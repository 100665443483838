'use client'
import { styled } from '../../config'

function Svg({ active, ...rest }) {
  if (active) {
    return (
      <svg
        width="56"
        height="56"
        viewBox="0 0 56 56"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}>
        <circle cx="28" cy="28" r="28" fill="#E30613" />
        <path
          d="M45 39H38.9625C38.9625 32.5745 37.7681 28.298 35.309 25.928C31.9995 22.7314 26.1918 22.8736 19.4726 23.0281L18.0391 23.062V39H12V17.1371H15.0203C16.4476 17.1371 17.9109 17.1015 19.3257 17.0675C26.7311 16.8883 34.3898 16.7029 39.5268 21.6577C43.21 25.2096 45 30.8827 45 39Z"
          fill="white"
        />
      </svg>
    )
  }

  return (
    <svg
      width="56"
      height="56"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}>
      <circle cx="28" cy="28" r="28" fill="#E30613" />
      <path
        d="M45 39H38.9625C38.9625 32.5745 37.7681 28.298 35.309 25.928C31.9995 22.7314 26.1918 22.8736 19.4726 23.0281L18.0391 23.062V39H12V17.1371H15.0203C16.4476 17.1371 17.9109 17.1015 19.3257 17.0675C26.7311 16.8883 34.3898 16.7029 39.5268 21.6577C43.21 25.2096 45 30.8827 45 39Z"
        fill="white"
      />
    </svg>
  )
}

export const Ce = styled(Svg)
