'use client'
import { styled } from '../../config'

function Svg({ active, ...rest }) {
  if (active) {
    return (
      <svg
        width="56"
        height="56"
        viewBox="0 0 56 56"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}>
        <circle cx="28" cy="28" r="28" fill="#E30121" />
        <g clipPath="url(#clip0_4078_1651)">
          <circle cx="27.9996" cy="28.0006" r="25.2" fill="#E30121" />
          <path
            d="M19.6004 26.3084C18.6854 26.0871 18.0015 25.2503 18 24.2486C18 23.0789 18.9309 22.1307 20.0739 22.1307C21.0537 22.1307 21.8564 22.8141 22.0765 23.743L26.3603 23.7398C26.5819 22.8124 27.3987 22.1259 28.3754 22.1242C29.3633 22.1225 30.1928 22.7963 30.4001 23.7365L34.649 23.7381C34.8596 22.7979 35.6938 22.1242 36.68 22.121C37.8262 22.1227 38.749 23.0629 38.749 24.2309C38.7505 25.231 38.0746 26.0758 37.166 26.3004L37.1801 31.2649C38.0856 31.4944 38.7552 32.3295 38.7552 33.3134C38.7567 34.3102 38.0793 35.181 37.1738 35.4088L37.1816 40.3571C38.0886 40.5833 38.7662 41.4201 38.7647 42.4153C38.7647 43.5833 37.8387 44.5332 36.6909 44.5332C35.8804 44.5332 35.1458 44.0097 34.8054 43.3135L30.4616 44.202C30.3824 45.2941 29.4881 46.1616 28.3926 46.1616C27.286 46.1616 26.4043 45.3166 26.3409 44.1988L26.3077 44.165L21.9733 43.3313C21.6407 44.0502 20.9253 44.5462 20.0974 44.5462C18.9512 44.5462 18.0203 43.5963 18.0203 42.4298C18.0188 41.4314 18.6789 40.6318 19.5922 40.4072L19.6032 35.3991C18.6899 35.1762 18.0092 34.341 18.0092 33.3378C18.0077 32.341 18.6741 31.5252 19.5859 31.3006L19.5827 26.3136L19.6004 26.3084Z"
            fill="white"
          />
          <path
            d="M27.2861 33.3328C27.2846 32.7141 27.7769 32.2117 28.3816 32.2117C28.9894 32.2117 29.4787 32.7125 29.4802 33.3311C29.4802 33.9498 28.9894 34.4523 28.3831 34.4523C27.7784 34.454 27.2861 33.9498 27.2861 33.3328Z"
            fill="#E30121"
          />
          <path
            d="M34.4683 21.294C32.5719 20.7381 30.5802 20.4313 28.5112 20.433C26.4802 20.4346 24.5171 20.7303 22.6619 21.2746L22.2233 19.68H22.2091C24.2133 19.0824 26.322 18.7705 28.5097 18.7673C30.737 18.7657 32.8869 19.0905 34.9164 19.6947L34.4683 21.294Z"
            fill="white"
          />
          <path
            d="M27.7153 18.3344C27.9796 18.3199 28.233 18.3344 28.4831 18.3327C28.7871 18.3311 29.0958 18.3327 29.3996 18.3456L29.3949 12.2276C29.129 12.171 28.8361 12.1322 28.5465 12.1338C28.2536 12.1338 27.975 12.1597 27.709 12.2146L27.7153 18.3344Z"
            fill="white"
          />
          <path
            d="M27.0107 18.3748C26.3473 18.4313 25.6841 18.4992 25.0334 18.5946C24.636 17.9467 24.4097 17.1858 24.4097 16.362C24.4082 14.4412 25.6682 12.8143 27.3905 12.299L27.392 14.0825C26.5831 14.5041 26.0386 15.3718 26.0418 16.3572C26.0419 17.1858 26.4282 17.9176 27.0107 18.3748Z"
            fill="white"
          />
          <path
            d="M24.6724 14.0451C24.3431 13.898 23.9807 13.8028 23.5992 13.8028C22.2204 13.8028 21.1074 14.9546 21.1074 16.3634C21.1074 17.6897 22.1048 18.7867 23.3902 18.9062C22.5671 19.0823 21.7596 19.3278 20.9633 19.5976C20.0483 18.827 19.4768 17.6638 19.4768 16.3666C19.4753 14.0371 21.3179 12.142 23.5975 12.1389C24.3921 12.1389 25.1379 12.3667 25.7726 12.7722C25.3231 13.1244 24.9511 13.5573 24.6724 14.0451Z"
            fill="white"
          />
          <path
            d="M30.1134 18.3731C30.7782 18.428 31.4399 18.4958 32.0921 18.5895C32.4895 17.9385 32.727 17.1792 32.7285 16.3553C32.727 14.4328 31.4668 12.8092 29.7255 12.2971L29.727 14.0823C30.5374 14.5024 31.0931 15.3699 31.0931 16.3569C31.0949 17.1809 30.7134 17.9143 30.1134 18.3731Z"
            fill="white"
          />
          <path
            d="M32.4628 14.0403C32.7778 13.8932 33.1372 13.7963 33.5219 13.7946C34.9007 13.7929 36.0295 14.9433 36.0327 16.3519C36.0327 17.6798 35.0243 18.7785 33.753 18.8995H33.7404C34.5603 19.074 35.382 19.3179 36.1673 19.5861H36.1799C37.0823 18.8138 37.6648 17.6491 37.6648 16.3502C37.6633 14.0222 35.8047 12.1305 33.5204 12.1305C32.7258 12.1305 31.9832 12.3615 31.3453 12.7687C31.7948 13.1211 32.17 13.5525 32.4628 14.0403Z"
            fill="white"
          />
          <path
            d="M28.6571 10.7771L28.6556 10.0016C29.0545 10.0485 29.3712 10.3732 29.4219 10.7754L28.6571 10.7771Z"
            fill="white"
          />
          <path
            d="M28.6556 11.0031H29.4219L29.4251 11.0015C29.3776 11.4119 29.0579 11.7382 28.6573 11.7866L28.6556 11.0031Z"
            fill="white"
          />
          <path
            d="M28.4419 11.7884C28.0398 11.7384 27.7248 11.4169 27.6772 11.0033H28.4403L28.4419 11.7884Z"
            fill="white"
          />
          <path
            d="M28.4419 10.7771H27.6772C27.7294 10.3732 28.043 10.0517 28.4403 10L28.4419 10.7771Z"
            fill="white"
          />
          <path
            d="M27.5128 33.3521L28.3708 32.475L29.2351 33.3505L28.3771 34.2278L27.5128 33.3521Z"
            fill="white"
          />
          <path
            d="M18.9863 33.3376C18.9848 32.7204 19.4771 32.2181 20.0818 32.2181C20.6896 32.2181 21.1804 32.7189 21.1804 33.3361C21.1804 33.9548 20.6881 34.4589 20.0818 34.4589C19.4771 34.4588 18.9863 33.958 18.9863 33.3376Z"
            fill="#E30121"
          />
          <path
            d="M22.124 42.3263L26.4821 43.1907C26.7433 42.614 27.2816 42.1406 27.8958 41.9855L27.8911 35.4135C27.7058 35.3667 27.4747 35.2795 27.3118 35.1841L21.7758 41.1905C21.9959 41.5024 22.1383 41.8771 22.162 42.2841L22.124 42.3263Z"
            fill="#E30121"
          />
          <path
            d="M26.6434 34.4863C26.5152 34.2861 26.4203 34.0614 26.3648 33.8256H22.0842C21.9052 34.6011 21.3259 35.2134 20.5645 35.3975L20.5723 40.3717C20.7354 40.4137 20.9237 40.4831 21.0677 40.5606L26.6353 34.4573L26.6434 34.4863Z"
            fill="#E30121"
          />
          <path
            d="M26.371 32.8353L22.1047 32.8546C21.9258 32.076 21.3306 31.4815 20.5675 31.2958L20.5565 26.3071C20.7401 26.265 20.8827 26.1923 21.0441 26.1003L26.6307 32.1828L26.371 32.8353Z"
            fill="#E30121"
          />
          <path
            d="M27.8893 31.2923L27.8862 26.3053C27.1357 26.1196 26.5486 25.5169 26.3616 24.7528L22.0764 24.7431C22.0146 24.9984 21.9196 25.2536 21.7755 25.4637L27.3525 31.4945C27.5203 31.3942 27.7039 31.32 27.8972 31.2732L27.8893 31.2923Z"
            fill="#E30121"
          />
          <path
            d="M29.3663 31.4604L34.9324 25.357C34.8136 25.168 34.7122 24.9692 34.6585 24.7446L30.4081 24.7381C30.2245 25.4957 29.6118 26.1177 28.8694 26.3068L28.8709 31.2938L29.3663 31.4604Z"
            fill="#E30121"
          />
          <path
            d="M30.4079 32.832L34.6584 32.8272C34.8325 32.0646 35.4215 31.4621 36.1766 31.2683L36.1812 26.2989C35.9991 26.2521 35.8045 26.1358 35.6414 26.0453L30.0864 32.1228C30.2337 32.3392 30.3429 32.5849 30.4014 32.8482L30.4079 32.832Z"
            fill="#E30121"
          />
          <path
            d="M30.0852 34.4766L35.6956 40.5736C35.8538 40.4847 36.0091 40.404 36.1879 40.3603L36.1894 35.4088C35.4406 35.2149 34.8769 34.5769 34.695 33.8159L30.4002 33.8304C30.3432 34.0696 30.2435 34.2989 30.1137 34.5025L30.0852 34.4766Z"
            fill="#E30121"
          />
          <path
            d="M29.3506 35.2052C29.2065 35.2828 29.0497 35.3425 28.8883 35.3878L28.8788 41.9921C29.4993 42.1504 30.0296 42.5931 30.2861 43.1795L34.6331 42.3248C34.6316 41.8999 34.7533 41.5849 34.9624 41.252L29.3901 35.1857L29.3506 35.2052Z"
            fill="#E30121"
          />
          <path
            d="M35.5732 24.2327C35.5732 23.6124 36.0672 23.1116 36.6719 23.1116C37.2782 23.1116 37.7673 23.6107 37.769 24.231C37.769 24.8482 37.2799 25.3522 36.6704 25.3505C36.0657 25.3507 35.5732 24.8514 35.5732 24.2327Z"
            fill="#E30121"
          />
          <path
            d="M35.5782 33.3215C35.5814 32.7028 36.069 32.1987 36.6769 32.1987C37.2832 32.1987 37.7723 32.6995 37.774 33.3181C37.774 33.9368 37.2849 34.4393 36.6769 34.4409C36.0722 34.4426 35.5797 33.9387 35.5782 33.3215Z"
            fill="#E30121"
          />
          <path
            d="M35.5924 42.4151C35.5909 41.7965 36.0832 41.2957 36.6895 41.294C37.2974 41.294 37.7867 41.7965 37.7882 42.4135C37.7882 43.0355 37.2959 43.5363 36.6895 43.533C36.0849 43.538 35.5924 43.0355 35.5924 42.4151Z"
            fill="#E30121"
          />
          <path
            d="M27.2941 44.042C27.2926 43.4233 27.7832 42.924 28.3895 42.9208C28.9959 42.9208 29.4867 43.42 29.4882 44.042C29.4867 44.6607 28.9959 45.1615 28.391 45.1615C27.7847 45.1633 27.2924 44.6608 27.2941 44.042Z"
            fill="#E30121"
          />
          <path
            d="M18.9957 42.4266C18.9942 41.8111 19.488 41.3086 20.0929 41.3086C20.6992 41.3069 21.19 41.8077 21.1915 42.4232C21.1915 43.0468 20.6992 43.546 20.0944 43.546C19.4895 43.546 18.9957 43.0469 18.9957 42.4266Z"
            fill="#E30121"
          />
          <path
            d="M18.98 24.2472C18.9785 23.6317 19.4708 23.1277 20.0754 23.126C20.6818 23.126 21.1726 23.6268 21.1741 24.2455C21.1741 24.8659 20.6818 25.3683 20.0769 25.3683C19.4708 25.37 18.98 24.8659 18.98 24.2472Z"
            fill="#E30121"
          />
          <path
            d="M27.2799 24.244C27.2784 23.6253 27.7707 23.1229 28.3754 23.1229C28.9832 23.1212 29.4708 23.6237 29.4757 24.2408C29.4742 24.8595 28.9817 25.362 28.3785 25.3636C27.7739 25.362 27.2816 24.861 27.2799 24.244Z"
            fill="#E30121"
          />
          <path
            d="M19.6004 26.3084C18.6854 26.0871 18.0015 25.2503 18 24.2486C18 23.0789 18.9309 22.1307 20.0739 22.1307C21.0537 22.1307 21.8564 22.8141 22.0765 23.743L26.3603 23.7398C26.5819 22.8124 27.3987 22.1259 28.3754 22.1242C29.3633 22.1225 30.1928 22.7963 30.4001 23.7365L34.649 23.7381C34.8596 22.7979 35.6938 22.1242 36.68 22.121C37.8262 22.1227 38.749 23.0629 38.749 24.2309C38.7505 25.231 38.0746 26.0758 37.166 26.3004L37.1801 31.2649C38.0856 31.4944 38.7552 32.3295 38.7552 33.3134C38.7567 34.3102 38.0793 35.181 37.1738 35.4088L37.1816 40.3571C38.0886 40.5833 38.7662 41.4201 38.7647 42.4153C38.7647 43.5833 37.8387 44.5332 36.6909 44.5332C35.8804 44.5332 35.1458 44.0097 34.8054 43.3135L30.4616 44.202C30.3824 45.2941 29.4881 46.1616 28.3926 46.1616C27.286 46.1616 26.4043 45.3166 26.3409 44.1988L26.3077 44.165L21.9733 43.3313C21.6407 44.0502 20.9253 44.5462 20.0974 44.5462C18.9512 44.5462 18.0203 43.5963 18.0203 42.4298C18.0188 41.4314 18.6789 40.6318 19.5922 40.4072L19.6032 35.3991C18.6899 35.1762 18.0092 34.341 18.0092 33.3378C18.0077 32.341 18.6741 31.5252 19.5859 31.3006L19.5827 26.3136L19.6004 26.3084Z"
            fill="white"
          />
          <path
            d="M27.2861 33.3328C27.2846 32.7141 27.7769 32.2117 28.3816 32.2117C28.9894 32.2117 29.4787 32.7125 29.4802 33.3311C29.4802 33.9498 28.9894 34.4523 28.3831 34.4523C27.7784 34.454 27.2861 33.9498 27.2861 33.3328Z"
            fill="#E30121"
          />
          <path
            d="M34.4683 21.294C32.5719 20.7381 30.5802 20.4313 28.5112 20.433C26.4802 20.4346 24.5171 20.7303 22.6619 21.2746L22.2233 19.68H22.2091C24.2133 19.0824 26.322 18.7705 28.5097 18.7673C30.737 18.7657 32.8869 19.0905 34.9164 19.6947L34.4683 21.294Z"
            fill="white"
          />
          <path
            d="M27.7153 18.3344C27.9796 18.3199 28.233 18.3344 28.4831 18.3327C28.7871 18.3311 29.0958 18.3327 29.3996 18.3456L29.3949 12.2276C29.129 12.171 28.8361 12.1322 28.5465 12.1338C28.2536 12.1338 27.975 12.1597 27.709 12.2146L27.7153 18.3344Z"
            fill="white"
          />
          <path
            d="M27.0107 18.3748C26.3473 18.4313 25.6841 18.4992 25.0334 18.5946C24.636 17.9467 24.4097 17.1858 24.4097 16.362C24.4082 14.4412 25.6682 12.8143 27.3905 12.299L27.392 14.0825C26.5831 14.5041 26.0386 15.3718 26.0418 16.3572C26.0419 17.1858 26.4282 17.9176 27.0107 18.3748Z"
            fill="white"
          />
          <path
            d="M24.6724 14.0451C24.3431 13.898 23.9807 13.8028 23.5992 13.8028C22.2204 13.8028 21.1074 14.9546 21.1074 16.3634C21.1074 17.6897 22.1048 18.7867 23.3902 18.9062C22.5671 19.0823 21.7596 19.3278 20.9633 19.5976C20.0483 18.827 19.4768 17.6638 19.4768 16.3666C19.4753 14.0371 21.3179 12.142 23.5975 12.1389C24.3921 12.1389 25.1379 12.3667 25.7726 12.7722C25.3231 13.1244 24.9511 13.5573 24.6724 14.0451Z"
            fill="white"
          />
          <path
            d="M30.1134 18.3731C30.7782 18.428 31.4399 18.4958 32.0921 18.5895C32.4895 17.9385 32.727 17.1792 32.7285 16.3553C32.727 14.4328 31.4668 12.8092 29.7255 12.2971L29.727 14.0823C30.5374 14.5024 31.0931 15.3699 31.0931 16.3569C31.0949 17.1809 30.7134 17.9143 30.1134 18.3731Z"
            fill="white"
          />
          <path
            d="M32.4628 14.0403C32.7778 13.8932 33.1372 13.7963 33.5219 13.7946C34.9007 13.7929 36.0295 14.9433 36.0327 16.3519C36.0327 17.6798 35.0243 18.7785 33.753 18.8995H33.7404C34.5603 19.074 35.382 19.3179 36.1673 19.5861H36.1799C37.0823 18.8138 37.6648 17.6491 37.6648 16.3502C37.6633 14.0222 35.8047 12.1305 33.5204 12.1305C32.7258 12.1305 31.9832 12.3615 31.3453 12.7687C31.7948 13.1211 32.17 13.5525 32.4628 14.0403Z"
            fill="white"
          />
          <path
            d="M28.6571 10.7771L28.6556 10.0016C29.0545 10.0485 29.3712 10.3732 29.4219 10.7754L28.6571 10.7771Z"
            fill="white"
          />
          <path
            d="M28.6556 11.0031H29.4219L29.4251 11.0015C29.3776 11.4119 29.0579 11.7382 28.6573 11.7866L28.6556 11.0031Z"
            fill="white"
          />
          <path
            d="M28.4419 11.7884C28.0398 11.7384 27.7248 11.4169 27.6772 11.0033H28.4403L28.4419 11.7884Z"
            fill="white"
          />
          <path
            d="M28.4419 10.7771H27.6772C27.7294 10.3732 28.043 10.0517 28.4403 10L28.4419 10.7771Z"
            fill="white"
          />
          <path
            d="M27.5128 33.3521L28.3708 32.475L29.2351 33.3505L28.3771 34.2278L27.5128 33.3521Z"
            fill="white"
          />
          <path
            d="M18.9863 33.3376C18.9848 32.7204 19.4771 32.2181 20.0818 32.2181C20.6896 32.2181 21.1804 32.7189 21.1804 33.3361C21.1804 33.9548 20.6881 34.4589 20.0818 34.4589C19.4771 34.4588 18.9863 33.958 18.9863 33.3376Z"
            fill="#E30121"
          />
          <path
            d="M22.124 42.3263L26.4821 43.1907C26.7433 42.614 27.2816 42.1406 27.8958 41.9855L27.8911 35.4135C27.7058 35.3667 27.4747 35.2795 27.3118 35.1841L21.7758 41.1905C21.9959 41.5024 22.1383 41.8771 22.162 42.2841L22.124 42.3263Z"
            fill="#E30121"
          />
          <path
            d="M26.6434 34.4863C26.5152 34.2861 26.4203 34.0614 26.3648 33.8256H22.0842C21.9052 34.6011 21.3259 35.2134 20.5645 35.3975L20.5723 40.3717C20.7354 40.4137 20.9237 40.4831 21.0677 40.5606L26.6353 34.4573L26.6434 34.4863Z"
            fill="#E30121"
          />
          <path
            d="M26.371 32.8353L22.1047 32.8546C21.9258 32.076 21.3306 31.4815 20.5675 31.2958L20.5565 26.3071C20.7401 26.265 20.8827 26.1923 21.0441 26.1003L26.6307 32.1828L26.371 32.8353Z"
            fill="#E30121"
          />
          <path
            d="M27.8893 31.2923L27.8862 26.3053C27.1357 26.1196 26.5486 25.5169 26.3616 24.7528L22.0764 24.7431C22.0146 24.9984 21.9196 25.2536 21.7755 25.4637L27.3525 31.4945C27.5203 31.3942 27.7039 31.32 27.8972 31.2732L27.8893 31.2923Z"
            fill="#E30121"
          />
          <path
            d="M29.3663 31.4604L34.9324 25.357C34.8136 25.168 34.7122 24.9692 34.6585 24.7446L30.4081 24.7381C30.2245 25.4957 29.6118 26.1177 28.8694 26.3068L28.8709 31.2938L29.3663 31.4604Z"
            fill="#E30121"
          />
          <path
            d="M30.4079 32.832L34.6584 32.8272C34.8325 32.0646 35.4215 31.4621 36.1766 31.2683L36.1812 26.2989C35.9991 26.2521 35.8045 26.1358 35.6414 26.0453L30.0864 32.1228C30.2337 32.3392 30.3429 32.5849 30.4014 32.8482L30.4079 32.832Z"
            fill="#E30121"
          />
          <path
            d="M30.0852 34.4766L35.6956 40.5736C35.8538 40.4847 36.0091 40.404 36.1879 40.3603L36.1894 35.4088C35.4406 35.2149 34.8769 34.5769 34.695 33.8159L30.4002 33.8304C30.3432 34.0696 30.2435 34.2989 30.1137 34.5025L30.0852 34.4766Z"
            fill="#E30121"
          />
          <path
            d="M29.3506 35.2052C29.2065 35.2828 29.0497 35.3425 28.8883 35.3878L28.8788 41.9921C29.4993 42.1504 30.0296 42.5931 30.2861 43.1795L34.6331 42.3248C34.6316 41.8999 34.7533 41.5849 34.9624 41.252L29.3901 35.1857L29.3506 35.2052Z"
            fill="#E30121"
          />
          <path
            d="M35.5732 24.2327C35.5732 23.6124 36.0672 23.1116 36.6719 23.1116C37.2782 23.1116 37.7673 23.6107 37.769 24.231C37.769 24.8482 37.2799 25.3522 36.6704 25.3505C36.0657 25.3507 35.5732 24.8514 35.5732 24.2327Z"
            fill="#E30121"
          />
          <path
            d="M35.5782 33.3215C35.5814 32.7028 36.069 32.1987 36.6769 32.1987C37.2832 32.1987 37.7723 32.6995 37.774 33.3181C37.774 33.9368 37.2849 34.4393 36.6769 34.4409C36.0722 34.4426 35.5797 33.9387 35.5782 33.3215Z"
            fill="#E30121"
          />
          <path
            d="M35.5924 42.4151C35.5909 41.7965 36.0832 41.2957 36.6895 41.294C37.2974 41.294 37.7867 41.7965 37.7882 42.4135C37.7882 43.0355 37.2959 43.5363 36.6895 43.533C36.0849 43.538 35.5924 43.0355 35.5924 42.4151Z"
            fill="#E30121"
          />
          <path
            d="M27.2941 44.042C27.2926 43.4233 27.7832 42.924 28.3895 42.9208C28.9959 42.9208 29.4867 43.42 29.4882 44.042C29.4867 44.6607 28.9959 45.1615 28.391 45.1615C27.7847 45.1633 27.2924 44.6608 27.2941 44.042Z"
            fill="#E30121"
          />
          <path
            d="M18.9957 42.4266C18.9942 41.8111 19.488 41.3086 20.0929 41.3086C20.6992 41.3069 21.19 41.8077 21.1915 42.4232C21.1915 43.0468 20.6992 43.546 20.0944 43.546C19.4895 43.546 18.9957 43.0469 18.9957 42.4266Z"
            fill="#E30121"
          />
          <path
            d="M18.98 24.2472C18.9785 23.6317 19.4708 23.1277 20.0754 23.126C20.6818 23.126 21.1726 23.6268 21.1741 24.2455C21.1741 24.8659 20.6818 25.3683 20.0769 25.3683C19.4708 25.37 18.98 24.8659 18.98 24.2472Z"
            fill="#E30121"
          />
          <path
            d="M27.2799 24.244C27.2784 23.6253 27.7707 23.1229 28.3754 23.1229C28.9832 23.1212 29.4708 23.6237 29.4757 24.2408C29.4742 24.8595 28.9817 25.362 28.3785 25.3636C27.7739 25.362 27.2816 24.861 27.2799 24.244Z"
            fill="#E30121"
          />
        </g>
        <rect
          x="3.14883"
          y="3.15078"
          width="49.7"
          height="49.7"
          rx="24.85"
          stroke="url(#paint0_linear_4078_1651)"
          strokeWidth="0.7"
        />
        <defs>
          <linearGradient
            id="paint0_linear_4078_1651"
            x1="27.9988"
            y1="2.80078"
            x2="27.9988"
            y2="53.2008"
            gradientUnits="userSpaceOnUse">
            <stop stopOpacity="0.1" />
            <stop offset="1" stopOpacity="0.05" />
          </linearGradient>
          <clipPath id="clip0_4078_1651">
            <rect
              x="2.79883"
              y="2.80078"
              width="50.4"
              height="50.4"
              rx="25.2"
              fill="white"
            />
          </clipPath>
        </defs>
      </svg>
    )
  }

  return (
    <svg
      width="56"
      height="56"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}>
      <circle cx="28" cy="28" r="27.5" fill="white" stroke="#F1F1F1" />
      <g clipPath="url(#clip0_4078_1646)">
        <circle
          cx="27.9996"
          cy="28.0006"
          r="25.2"
          fill="#DA291C"
          fill-opacity="0.05"
        />
        <path
          d="M19.6004 26.3084C18.6854 26.0871 18.0015 25.2503 18 24.2486C18 23.0789 18.9309 22.1307 20.0739 22.1307C21.0537 22.1307 21.8564 22.8141 22.0765 23.743L26.3603 23.7398C26.5819 22.8124 27.3987 22.1259 28.3754 22.1242C29.3633 22.1225 30.1928 22.7963 30.4001 23.7365L34.649 23.7381C34.8596 22.7979 35.6938 22.1242 36.68 22.121C37.8262 22.1227 38.749 23.0629 38.749 24.2309C38.7505 25.231 38.0746 26.0758 37.166 26.3004L37.1801 31.2649C38.0856 31.4944 38.7552 32.3295 38.7552 33.3134C38.7567 34.3102 38.0793 35.181 37.1738 35.4088L37.1816 40.3571C38.0886 40.5833 38.7662 41.4201 38.7647 42.4153C38.7647 43.5833 37.8387 44.5332 36.6909 44.5332C35.8804 44.5332 35.1458 44.0097 34.8054 43.3135L30.4616 44.202C30.3824 45.2941 29.4881 46.1616 28.3926 46.1616C27.286 46.1616 26.4043 45.3166 26.3409 44.1988L26.3077 44.165L21.9733 43.3313C21.6407 44.0502 20.9253 44.5462 20.0974 44.5462C18.9512 44.5462 18.0203 43.5963 18.0203 42.4298C18.0188 41.4314 18.6789 40.6318 19.5922 40.4072L19.6032 35.3991C18.6899 35.1762 18.0092 34.341 18.0092 33.3378C18.0077 32.341 18.6741 31.5252 19.5859 31.3006L19.5827 26.3136L19.6004 26.3084Z"
          fill="#E30121"
        />
        <path
          d="M27.2861 33.3328C27.2846 32.7141 27.7769 32.2117 28.3816 32.2117C28.9894 32.2117 29.4787 32.7125 29.4802 33.3311C29.4802 33.9498 28.9894 34.4523 28.3831 34.4523C27.7784 34.454 27.2861 33.9498 27.2861 33.3328Z"
          fill="white"
        />
        <path
          d="M34.4683 21.294C32.5719 20.7381 30.5802 20.4313 28.5112 20.433C26.4802 20.4346 24.5171 20.7303 22.6619 21.2746L22.2233 19.68H22.2091C24.2133 19.0824 26.322 18.7705 28.5097 18.7673C30.737 18.7657 32.8869 19.0905 34.9164 19.6947L34.4683 21.294Z"
          fill="#E30121"
        />
        <path
          d="M27.7153 18.3344C27.9796 18.3199 28.233 18.3344 28.4831 18.3327C28.7871 18.3311 29.0958 18.3327 29.3996 18.3456L29.3949 12.2276C29.129 12.171 28.8361 12.1322 28.5465 12.1338C28.2536 12.1338 27.975 12.1597 27.709 12.2146L27.7153 18.3344Z"
          fill="#E30121"
        />
        <path
          d="M27.0107 18.3748C26.3473 18.4313 25.6841 18.4992 25.0334 18.5946C24.636 17.9467 24.4097 17.1858 24.4097 16.362C24.4082 14.4412 25.6682 12.8143 27.3905 12.299L27.392 14.0825C26.5831 14.5041 26.0386 15.3718 26.0418 16.3572C26.0419 17.1858 26.4282 17.9176 27.0107 18.3748Z"
          fill="#E30121"
        />
        <path
          d="M24.6724 14.0451C24.3431 13.898 23.9807 13.8028 23.5992 13.8028C22.2204 13.8028 21.1074 14.9546 21.1074 16.3634C21.1074 17.6897 22.1048 18.7867 23.3902 18.9062C22.5671 19.0823 21.7596 19.3278 20.9633 19.5976C20.0483 18.827 19.4768 17.6638 19.4768 16.3666C19.4753 14.0371 21.3179 12.142 23.5975 12.1389C24.3921 12.1389 25.1379 12.3667 25.7726 12.7722C25.3231 13.1244 24.9511 13.5573 24.6724 14.0451Z"
          fill="#E30121"
        />
        <path
          d="M30.1134 18.3731C30.7782 18.428 31.4399 18.4958 32.0921 18.5895C32.4895 17.9385 32.727 17.1792 32.7285 16.3553C32.727 14.4328 31.4668 12.8092 29.7255 12.2971L29.727 14.0823C30.5374 14.5024 31.0931 15.3699 31.0931 16.3569C31.0949 17.1809 30.7134 17.9143 30.1134 18.3731Z"
          fill="#E30121"
        />
        <path
          d="M32.4628 14.0403C32.7778 13.8932 33.1372 13.7963 33.5219 13.7946C34.9007 13.7929 36.0295 14.9433 36.0327 16.3519C36.0327 17.6798 35.0243 18.7785 33.753 18.8995H33.7404C34.5603 19.074 35.382 19.3179 36.1673 19.5861H36.1799C37.0823 18.8138 37.6648 17.6491 37.6648 16.3502C37.6633 14.0222 35.8047 12.1305 33.5204 12.1305C32.7258 12.1305 31.9832 12.3615 31.3453 12.7687C31.7948 13.1211 32.17 13.5525 32.4628 14.0403Z"
          fill="#E30121"
        />
        <path
          d="M28.6571 10.7771L28.6556 10.0016C29.0545 10.0485 29.3712 10.3732 29.4219 10.7754L28.6571 10.7771Z"
          fill="#E30121"
        />
        <path
          d="M28.6556 11.0031H29.4219L29.4251 11.0015C29.3776 11.4119 29.0579 11.7382 28.6573 11.7866L28.6556 11.0031Z"
          fill="#E30121"
        />
        <path
          d="M28.4419 11.7884C28.0398 11.7384 27.7248 11.4169 27.6772 11.0033H28.4403L28.4419 11.7884Z"
          fill="#E30121"
        />
        <path
          d="M28.4419 10.7771H27.6772C27.7294 10.3732 28.043 10.0517 28.4403 10L28.4419 10.7771Z"
          fill="#E30121"
        />
        <path
          d="M27.5128 33.3521L28.3708 32.475L29.2351 33.3505L28.3771 34.2278L27.5128 33.3521Z"
          fill="#E30121"
        />
        <path
          d="M18.9863 33.3376C18.9848 32.7204 19.4771 32.2181 20.0818 32.2181C20.6896 32.2181 21.1804 32.7189 21.1804 33.3361C21.1804 33.9548 20.6881 34.4589 20.0818 34.4589C19.4771 34.4588 18.9863 33.958 18.9863 33.3376Z"
          fill="white"
        />
        <path
          d="M22.124 42.3263L26.4821 43.1907C26.7433 42.614 27.2816 42.1406 27.8958 41.9855L27.8911 35.4135C27.7058 35.3667 27.4747 35.2795 27.3118 35.1841L21.7758 41.1905C21.9959 41.5024 22.1383 41.8771 22.162 42.2841L22.124 42.3263Z"
          fill="white"
        />
        <path
          d="M26.6434 34.4863C26.5152 34.2861 26.4203 34.0614 26.3648 33.8256H22.0842C21.9052 34.6011 21.3259 35.2134 20.5645 35.3975L20.5723 40.3717C20.7354 40.4137 20.9237 40.4831 21.0677 40.5606L26.6353 34.4573L26.6434 34.4863Z"
          fill="white"
        />
        <path
          d="M26.371 32.8353L22.1047 32.8546C21.9258 32.076 21.3306 31.4815 20.5675 31.2958L20.5565 26.3071C20.7401 26.265 20.8827 26.1923 21.0441 26.1003L26.6307 32.1828L26.371 32.8353Z"
          fill="white"
        />
        <path
          d="M27.8893 31.2923L27.8862 26.3053C27.1357 26.1196 26.5486 25.5169 26.3616 24.7528L22.0764 24.7431C22.0146 24.9984 21.9196 25.2536 21.7755 25.4637L27.3525 31.4945C27.5203 31.3942 27.7039 31.32 27.8972 31.2732L27.8893 31.2923Z"
          fill="white"
        />
        <path
          d="M29.3663 31.4604L34.9324 25.357C34.8136 25.168 34.7122 24.9692 34.6585 24.7446L30.4081 24.7381C30.2245 25.4957 29.6118 26.1177 28.8694 26.3068L28.8709 31.2938L29.3663 31.4604Z"
          fill="white"
        />
        <path
          d="M30.4079 32.832L34.6584 32.8272C34.8325 32.0646 35.4215 31.4621 36.1766 31.2683L36.1812 26.2989C35.9991 26.2521 35.8045 26.1358 35.6414 26.0453L30.0864 32.1228C30.2337 32.3392 30.3429 32.5849 30.4014 32.8482L30.4079 32.832Z"
          fill="white"
        />
        <path
          d="M30.0852 34.4766L35.6956 40.5736C35.8538 40.4847 36.0091 40.404 36.1879 40.3603L36.1894 35.4088C35.4406 35.2149 34.8769 34.5769 34.695 33.8159L30.4002 33.8304C30.3432 34.0696 30.2435 34.2989 30.1137 34.5025L30.0852 34.4766Z"
          fill="white"
        />
        <path
          d="M29.3506 35.2052C29.2065 35.2828 29.0497 35.3425 28.8883 35.3878L28.8788 41.9921C29.4993 42.1504 30.0296 42.5931 30.2861 43.1795L34.6331 42.3248C34.6316 41.8999 34.7533 41.5849 34.9624 41.252L29.3901 35.1857L29.3506 35.2052Z"
          fill="white"
        />
        <path
          d="M35.5732 24.2327C35.5732 23.6124 36.0672 23.1116 36.6719 23.1116C37.2782 23.1116 37.7673 23.6107 37.769 24.231C37.769 24.8482 37.2799 25.3522 36.6704 25.3505C36.0657 25.3507 35.5732 24.8514 35.5732 24.2327Z"
          fill="white"
        />
        <path
          d="M35.5782 33.3215C35.5814 32.7028 36.069 32.1987 36.6769 32.1987C37.2832 32.1987 37.7723 32.6995 37.774 33.3181C37.774 33.9368 37.2849 34.4393 36.6769 34.4409C36.0722 34.4426 35.5797 33.9387 35.5782 33.3215Z"
          fill="white"
        />
        <path
          d="M35.5924 42.4151C35.5909 41.7965 36.0832 41.2957 36.6895 41.294C37.2974 41.294 37.7867 41.7965 37.7882 42.4135C37.7882 43.0355 37.2959 43.5363 36.6895 43.533C36.0849 43.538 35.5924 43.0355 35.5924 42.4151Z"
          fill="white"
        />
        <path
          d="M27.2941 44.042C27.2926 43.4233 27.7832 42.924 28.3895 42.9208C28.9959 42.9208 29.4867 43.42 29.4882 44.042C29.4867 44.6607 28.9959 45.1615 28.391 45.1615C27.7847 45.1633 27.2924 44.6608 27.2941 44.042Z"
          fill="white"
        />
        <path
          d="M18.9957 42.4266C18.9942 41.8111 19.488 41.3086 20.0929 41.3086C20.6992 41.3069 21.19 41.8077 21.1915 42.4232C21.1915 43.0468 20.6992 43.546 20.0944 43.546C19.4895 43.546 18.9957 43.0469 18.9957 42.4266Z"
          fill="white"
        />
        <path
          d="M18.98 24.2472C18.9785 23.6317 19.4708 23.1277 20.0754 23.126C20.6818 23.126 21.1726 23.6268 21.1741 24.2455C21.1741 24.8659 20.6818 25.3683 20.0769 25.3683C19.4708 25.37 18.98 24.8659 18.98 24.2472Z"
          fill="white"
        />
        <path
          d="M27.2799 24.244C27.2784 23.6253 27.7707 23.1229 28.3754 23.1229C28.9832 23.1212 29.4708 23.6237 29.4757 24.2408C29.4742 24.8595 28.9817 25.362 28.3785 25.3636C27.7739 25.362 27.2816 24.861 27.2799 24.244Z"
          fill="white"
        />
      </g>
      <rect
        x="3.14883"
        y="3.15078"
        width="49.7"
        height="49.7"
        rx="24.85"
        stroke="url(#paint0_linear_4078_1646)"
        strokeWidth="0.7"
      />
      <defs>
        <linearGradient
          id="paint0_linear_4078_1646"
          x1="27.9988"
          y1="2.80078"
          x2="27.9988"
          y2="53.2008"
          gradientUnits="userSpaceOnUse">
          <stop stopOpacity="0.1" />
          <stop offset="1" stopOpacity="0.05" />
        </linearGradient>
        <clipPath id="clip0_4078_1646">
          <rect
            x="2.79883"
            y="2.80078"
            width="50.4"
            height="50.4"
            rx="25.2"
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export const PoliciaForalNavarra = styled(Svg)
