'use client'
import { styled } from '../../config'

function Svg({ active, ...rest }) {
  if (active) {
    return (
      <svg
        width="56"
        height="56"
        viewBox="0 0 56 56"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}>
        <circle cx="28" cy="28" r="28" fill="#2D2E83" />
        <g clipPath="url(#clip0_2753_6195)">
          <circle cx="27.9996" cy="28.0005" r="25.2" fill="#2D2E83" />
        </g>
        <rect
          x="3.14883"
          y="3.15078"
          width="49.7"
          height="49.7"
          rx="24.85"
          stroke="url(#paint0_linear_2753_6195)"
          strokeWidth="0.7"
        />
        <path
          d="M9.88532 19.7273H13.271L17.8051 30.794H17.984L22.5181 19.7273H25.9038V35H23.249V24.5075H23.1073L18.8864 34.9553H16.9027L12.6818 24.4851H12.5401V35H9.88532V19.7273ZM31.5783 19.7273V35H28.8116V19.7273H31.5783ZM34.4693 35V19.7273H40.1965C41.3698 19.7273 42.3542 19.9311 43.1497 20.3388C43.9501 20.7464 44.5541 21.3182 44.9618 22.054C45.3744 22.7848 45.5808 23.6374 45.5808 24.6119C45.5808 25.5913 45.372 26.4414 44.9543 27.1623C44.5417 27.8782 43.9327 28.4325 43.1273 28.8253C42.3219 29.2131 41.3325 29.407 40.1592 29.407H36.0801V27.1101H39.7864C40.4725 27.1101 41.0342 27.0156 41.4717 26.8267C41.9092 26.6328 42.2324 26.3519 42.4412 25.984C42.655 25.6112 42.7619 25.1538 42.7619 24.6119C42.7619 24.07 42.655 23.6076 42.4412 23.2248C42.2274 22.837 41.9018 22.5437 41.4643 22.3448C41.0268 22.141 40.4625 22.0391 39.7715 22.0391H37.236V35H34.4693ZM42.3592 28.0795L46.1401 35H43.0527L39.3389 28.0795H42.3592Z"
          fill="white"
        />
        <defs>
          <linearGradient
            id="paint0_linear_2753_6195"
            x1="27.9988"
            y1="2.80078"
            x2="27.9988"
            y2="53.2008"
            gradientUnits="userSpaceOnUse">
            <stop stopOpacity="0.25" />
            <stop offset="1" stopOpacity="0.15" />
          </linearGradient>
          <clipPath id="clip0_2753_6195">
            <rect
              x="2.79883"
              y="2.80078"
              width="50.4"
              height="50.4"
              rx="25.2"
              fill="white"
            />
          </clipPath>
        </defs>
      </svg>
    )
  }
  return (
    <svg
      width="56"
      height="56"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}>
      <g opacity="0.5">
        <circle cx="28" cy="28" r="28" fill="#2D2E83" />
        <g clipPath="url(#clip0_2753_6202)">
          <circle cx="27.9996" cy="28.0005" r="25.2" fill="#2D2E83" />
        </g>
        <rect
          x="3.14883"
          y="3.15078"
          width="49.7"
          height="49.7"
          rx="24.85"
          stroke="url(#paint0_linear_2753_6202)"
          strokeWidth="0.7"
        />
        <path
          d="M9.88532 19.7273H13.271L17.8051 30.794H17.984L22.5181 19.7273H25.9038V35H23.249V24.5075H23.1073L18.8864 34.9553H16.9027L12.6818 24.4851H12.5401V35H9.88532V19.7273ZM31.5783 19.7273V35H28.8116V19.7273H31.5783ZM34.4693 35V19.7273H40.1965C41.3698 19.7273 42.3542 19.9311 43.1497 20.3388C43.9501 20.7464 44.5541 21.3182 44.9618 22.054C45.3744 22.7848 45.5808 23.6374 45.5808 24.6119C45.5808 25.5913 45.372 26.4414 44.9543 27.1623C44.5417 27.8782 43.9327 28.4325 43.1273 28.8253C42.3219 29.2131 41.3325 29.407 40.1592 29.407H36.0801V27.1101H39.7864C40.4725 27.1101 41.0342 27.0156 41.4717 26.8267C41.9092 26.6328 42.2324 26.3519 42.4412 25.984C42.655 25.6112 42.7619 25.1538 42.7619 24.6119C42.7619 24.07 42.655 23.6076 42.4412 23.2248C42.2274 22.837 41.9018 22.5437 41.4643 22.3448C41.0268 22.141 40.4625 22.0391 39.7715 22.0391H37.236V35H34.4693ZM42.3592 28.0795L46.1401 35H43.0527L39.3389 28.0795H42.3592Z"
          fill="white"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_2753_6202"
          x1="27.9988"
          y1="2.80078"
          x2="27.9988"
          y2="53.2008"
          gradientUnits="userSpaceOnUse">
          <stop stopOpacity="0.25" />
          <stop offset="1" stopOpacity="0.15" />
        </linearGradient>
        <clipPath id="clip0_2753_6202">
          <rect
            x="2.79883"
            y="2.80078"
            width="50.4"
            height="50.4"
            rx="25.2"
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export const Mir = styled(Svg)
