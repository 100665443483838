'use client'
import { styled } from '../../config'

function Svg({ active, ...rest }) {
  if (active) {
    return (
      <svg
        width="56"
        height="56"
        viewBox="0 0 56 56"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}>
        <circle cx="28" cy="28" r="28" fill="#F4F4F4" />
        <g clipPath="url(#clip0_356_3272)">
          <circle cx="28" cy="28.001" r="25.2" fill="#EEEEEE" />
          <g filter="url(#filter0_d_356_3272)">
            <rect
              x="9.79999"
              y="16.801"
              width="37.1"
              height="37.8"
              rx="2.1"
              fill="white"
            />
            <rect
              x="10.15"
              y="17.151"
              width="36.4"
              height="37.1"
              rx="1.75"
              stroke="url(#paint0_linear_356_3272)"
              strokeWidth="0.7"
            />
          </g>
          <path
            d="M40.6 29.821L37.8 30.801V25.901H43.4V30.801L40.6 29.821Z"
            fill="#F0F0F0"
          />
          <rect
            x="11.9"
            y="34.301"
            width="2.8"
            height="2.8"
            rx="0.7"
            fill="#E1E1E1"
          />
          <rect
            x="11.9"
            y="21.7009"
            width="19.6"
            height="2.8"
            rx="0.7"
            fill="#E1E1E1"
          />
          <rect
            x="11.9"
            y="25.901"
            width="13.3"
            height="2.8"
            rx="0.7"
            fill="#E1E1E1"
          />
          <rect
            x="16.8"
            y="34.301"
            width="24.5"
            height="2.8"
            rx="0.7"
            fill="#F0F0F0"
          />
          <rect
            x="16.8"
            y="38.501"
            width="13.3"
            height="2.8"
            rx="0.7"
            fill="#F0F0F0"
          />
          <rect
            x="16.8"
            y="42.7009"
            width="20.3"
            height="2.8"
            rx="0.7"
            fill="#F0F0F0"
          />
          <rect
            x="11.9"
            y="38.501"
            width="2.8"
            height="2.8"
            rx="0.7"
            fill="#E1E1E1"
          />
          <rect
            x="11.9"
            y="42.7009"
            width="2.8"
            height="2.8"
            rx="0.7"
            fill="#E1E1E1"
          />
          <g filter="url(#filter1_d_356_3272)">
            <circle cx="40.6" cy="23.101" r="4.2" fill="#F0F0F0" />
          </g>
          <circle cx="40.6" cy="23.101" r="2.8" fill="#E1E1E1" />
        </g>
        <rect
          x="3.15078"
          y="3.15078"
          width="49.7"
          height="49.7"
          rx="24.85"
          stroke="url(#paint1_linear_356_3272)"
          strokeWidth="0.7"
        />
        <circle cx="28" cy="28" r="28" fill="#F7DA00" />
        <rect
          x="3.14883"
          y="3.15078"
          width="49.7"
          height="49.7"
          rx="24.85"
          stroke="url(#paint2_linear_356_3272)"
          strokeOpacity="0.5"
          strokeWidth="0.7"
        />
        <path
          d="M35.3818 25.2454H28.4H21.4318V24.0863V22.9136H28.4H35.3818V24.0863V25.2454Z"
          fill="#00457D"
        />
        <path
          d="M34.2091 14.2C33.3637 14.2 32.5727 14.4454 31.8773 14.8409C31.1955 14.4454 30.4046 14.2 29.5591 14.2C29.15 14.2 28.7546 14.2545 28.3727 14.3636C28.0046 14.2681 27.6364 14.2 27.2409 14.2C26.3955 14.2 25.6182 14.4454 24.9227 14.8409C24.2409 14.4454 23.4364 14.2 22.5909 14.2C20.0273 14.2 17.9409 16.2863 17.9409 18.85V21.1818H20.2727V18.85C20.2727 17.5681 21.3091 16.5181 22.6046 16.5181C22.8091 16.5181 23.0136 16.559 23.2046 16.6C22.8364 17.2681 22.6046 18.0181 22.6046 18.8363V21.1681H24.9364V18.85C24.9364 17.5681 25.9727 16.5181 27.2682 16.5181V21.1681H29.6V16.5181C30.8818 16.5181 31.9318 17.5545 31.9318 18.85V21.1818H34.2091V18.85C34.2091 18.0318 33.9773 17.2818 33.6091 16.6136C33.8 16.559 34.0046 16.5318 34.2091 16.5318C35.4909 16.5318 36.5409 17.5681 36.5409 18.8636V21.1954H38.8727V18.85C38.8591 16.2727 36.7864 14.2 34.2091 14.2Z"
          fill="#00457D"
        />
        <path
          d="M28.9046 11.5V10.4091H27.8955V11.5H26.8046V12.5227H27.8955V13.6136H28.9046V12.5227H30.0091V11.5H28.9046Z"
          fill="#00457D"
        />
        <path
          d="M40.0318 26.9773C40.0318 29.7318 39.0773 32.2546 37.4818 34.2455C36.5545 30.0864 32.8455 26.9773 28.4136 26.9773C25.6318 26.9773 23.1364 28.2046 21.4318 30.1409V26.9773H9.79999C9.79999 37.2591 18.1318 45.5909 28.4 45.5909C38.6682 45.5909 47 37.2591 47 26.9909H40.0318V26.9773ZM12.3091 29.3091H19.1V36.2909C19.1 38.1046 19.6318 39.7955 20.5318 41.2409C16.1682 38.8137 13.0454 34.45 12.3091 29.3091ZM28.4 43.2591C24.5545 43.2591 21.4182 40.1364 21.4182 36.2773C21.4182 32.4182 24.5409 29.3091 28.4 29.3091C32.2591 29.3091 35.3682 32.4318 35.3682 36.2637C34.5773 36.8637 33.7182 37.3546 32.7909 37.7364C32.9409 37.2728 33.05 36.7955 33.05 36.2773C33.05 33.6455 30.9636 31.6273 28.4 31.6273C25.8364 31.6273 23.75 33.7 23.75 36.2773C23.75 38.8546 25.8227 40.9273 28.4 40.9273C35.3136 40.9273 41.0545 35.8955 42.1591 29.3091H44.5182C43.4 37.1909 36.5955 43.2591 28.4 43.2591ZM30.7318 36.2773C30.7318 37.5591 29.6954 38.5955 28.4136 38.5955C27.1318 38.5955 26.0954 37.5591 26.0954 36.2773C26.0954 34.9955 27.1318 33.9591 28.4136 33.9591C29.6954 33.9591 30.7318 34.9955 30.7318 36.2773Z"
          fill="#00457D"
        />
        <defs>
          <filter
            id="filter0_d_356_3272"
            x="6.29999"
            y="16.101"
            width="44.1"
            height="44.8"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="2.8" />
            <feGaussianBlur stdDeviation="1.75" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_356_3272"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_356_3272"
              result="shape"
            />
          </filter>
          <filter
            id="filter1_d_356_3272"
            x="33.6"
            y="17.501"
            width="14"
            height="14"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="1.4" />
            <feGaussianBlur stdDeviation="1.4" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_356_3272"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_356_3272"
              result="shape"
            />
          </filter>
          <linearGradient
            id="paint0_linear_356_3272"
            x1="28.35"
            y1="16.801"
            x2="28.35"
            y2="54.601"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="white" stopOpacity="0.44" />
            <stop offset="1" stopColor="white" stopOpacity="0" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_356_3272"
            x1="28.0008"
            y1="2.80078"
            x2="28.0008"
            y2="53.2008"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#DADADA" />
            <stop offset="1" stopColor="#CDCDCD" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_356_3272"
            x1="27.9988"
            y1="2.80078"
            x2="27.9988"
            y2="53.2008"
            gradientUnits="userSpaceOnUse">
            <stop stopOpacity="0.25" />
            <stop offset="1" stopOpacity="0.15" />
          </linearGradient>
          <clipPath id="clip0_356_3272">
            <rect
              x="2.80078"
              y="2.80078"
              width="50.4"
              height="50.4"
              rx="25.2"
              fill="white"
            />
          </clipPath>
        </defs>
      </svg>

      // <svg
      //   version="1.1"
      //   id="Capa_1"
      //   xmlns="http://www.w3.org/2000/svg"
      //   x="0"
      //   y="0"
      //   viewBox="0 0 272.8 258"
      //   {...rest}>
      //   <style>{'.st0{fill:#00457d}'}</style>
      //   <switch>
      //     <g>
      //       <polygon
      //         className="st0"
      //         points="187.6,108.8 136.4,108.8 85.3,108.8 85.3,100.3 85.3,91.7 136.4,91.7 187.6,91.7 187.6,100.3"
      //       />
      //       <path
      //         className="st0"
      //         d="M179 27.8c-6.2 0-12 1.8-17.1 4.7-5-2.9-10.8-4.7-17-4.7-3 0-5.9.4-8.7 1.2-2.7-.7-5.4-1.2-8.3-1.2-6.2 0-11.9 1.8-17 4.7-5-2.9-10.9-4.7-17.1-4.7-18.8 0-34.1 15.3-34.1 34.1V79h17.1V61.9c0-9.4 7.6-17.1 17.1-17.1 1.5 0 3 .3 4.4.6-2.7 4.9-4.4 10.4-4.4 16.4v17.1H111v-17c0-9.4 7.6-17.1 17.1-17.1v34.1h17.1V44.8c9.4 0 17.1 7.6 17.1 17.1V79H179V61.9c0-6-1.7-11.5-4.4-16.4 1.4-.4 2.9-.6 4.4-.6 9.4 0 17.1 7.6 17.1 17.1v17.1h17.1V61.9c-.1-18.9-15.3-34.1-34.2-34.1z"
      //       />
      //       <polygon
      //         className="st0"
      //         points="140.1,8 140.1,0 132.7,0 132.7,8 124.7,8 124.7,15.5 132.7,15.5 132.7,23.5 140.1,23.5 140.1,15.5 148.2,15.5 148.2,8"
      //       />
      //       <path
      //         className="st0"
      //         d="M221.7 121.5c0 20.2-7 38.7-18.7 53.3-6.8-30.5-34-53.3-66.5-53.3-20.4 0-38.7 9-51.2 23.2v-23.2H0C0 196.9 61.1 258 136.4 258c75.3 0 136.4-61.1 136.4-136.4h-51.1zM18.4 138.6h49.8v51.2c0 13.3 3.9 25.7 10.5 36.3-32-17.8-54.9-49.8-60.3-87.5zm118 102.3c-28.2 0-51.2-22.9-51.2-51.2s22.9-51.1 51.2-51.1 51.1 22.9 51.1 51c-5.8 4.4-12.1 8-18.9 10.8 1.1-3.4 1.9-6.9 1.9-10.7 0-19.3-15.3-34.1-34.1-34.1s-34.1 15.2-34.1 34.1 15.2 34.1 34.1 34.1c50.7 0 92.8-36.9 100.9-85.2h17.3c-8.2 57.8-58.1 102.3-118.2 102.3zm17.1-51.2c0 9.4-7.6 17-17 17s-17-7.6-17-17 7.6-17 17-17 17 7.6 17 17z"
      //       />
      //     </g>
      //   </switch>
      // </svg>
    )
  }

  return (
    <svg
      width="56"
      height="56"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}>
      <circle cx="28" cy="28" r="28" fill="#F4F4F4" />
      <g clipPath="url(#clip0_356_3337)">
        <circle cx="28" cy="28.001" r="25.2" fill="#EEEEEE" />
        <g filter="url(#filter0_d_356_3337)">
          <rect
            x="9.79999"
            y="16.801"
            width="37.1"
            height="37.8"
            rx="2.1"
            fill="white"
          />
          <rect
            x="10.15"
            y="17.151"
            width="36.4"
            height="37.1"
            rx="1.75"
            stroke="url(#paint0_linear_356_3337)"
            strokeWidth="0.7"
          />
        </g>
        <path
          d="M40.6 29.821L37.8 30.801V25.901H43.4V30.801L40.6 29.821Z"
          fill="#F0F0F0"
        />
        <rect
          x="11.9"
          y="34.301"
          width="2.8"
          height="2.8"
          rx="0.7"
          fill="#E1E1E1"
        />
        <rect
          x="11.9"
          y="21.7009"
          width="19.6"
          height="2.8"
          rx="0.7"
          fill="#E1E1E1"
        />
        <rect
          x="11.9"
          y="25.901"
          width="13.3"
          height="2.8"
          rx="0.7"
          fill="#E1E1E1"
        />
        <rect
          x="16.8"
          y="34.301"
          width="24.5"
          height="2.8"
          rx="0.7"
          fill="#F0F0F0"
        />
        <rect
          x="16.8"
          y="38.501"
          width="13.3"
          height="2.8"
          rx="0.7"
          fill="#F0F0F0"
        />
        <rect
          x="16.8"
          y="42.7009"
          width="20.3"
          height="2.8"
          rx="0.7"
          fill="#F0F0F0"
        />
        <rect
          x="11.9"
          y="38.501"
          width="2.8"
          height="2.8"
          rx="0.7"
          fill="#E1E1E1"
        />
        <rect
          x="11.9"
          y="42.7009"
          width="2.8"
          height="2.8"
          rx="0.7"
          fill="#E1E1E1"
        />
        <g filter="url(#filter1_d_356_3337)">
          <circle cx="40.6" cy="23.101" r="4.2" fill="#F0F0F0" />
        </g>
        <circle cx="40.6" cy="23.101" r="2.8" fill="#E1E1E1" />
      </g>
      <rect
        x="3.15078"
        y="3.15078"
        width="49.7"
        height="49.7"
        rx="24.85"
        stroke="url(#paint1_linear_356_3337)"
        strokeWidth="0.7"
      />
      <circle cx="28" cy="28" r="28" fill="white" />
      <circle opacity="0.5" cx="28" cy="28" r="25" fill="#F7DA00" />
      <g opacity="0.5">
        <rect
          x="3.14883"
          y="3.15078"
          width="49.7"
          height="49.7"
          rx="24.85"
          stroke="url(#paint2_linear_356_3337)"
          strokeOpacity="0.5"
          strokeWidth="0.7"
        />
      </g>
      <g opacity="0.5">
        <path
          d="M35.3818 25.2454H28.4H21.4318V24.0863V22.9136H28.4H35.3818V24.0863V25.2454Z"
          fill="#00457D"
        />
        <path
          d="M34.2091 14.2C33.3637 14.2 32.5727 14.4454 31.8773 14.8409C31.1955 14.4454 30.4046 14.2 29.5591 14.2C29.15 14.2 28.7546 14.2545 28.3727 14.3636C28.0046 14.2681 27.6364 14.2 27.2409 14.2C26.3955 14.2 25.6182 14.4454 24.9227 14.8409C24.2409 14.4454 23.4364 14.2 22.5909 14.2C20.0273 14.2 17.9409 16.2863 17.9409 18.85V21.1818H20.2727V18.85C20.2727 17.5681 21.3091 16.5181 22.6046 16.5181C22.8091 16.5181 23.0136 16.559 23.2046 16.6C22.8364 17.2681 22.6046 18.0181 22.6046 18.8363V21.1681H24.9364V18.85C24.9364 17.5681 25.9727 16.5181 27.2682 16.5181V21.1681H29.6V16.5181C30.8818 16.5181 31.9318 17.5545 31.9318 18.85V21.1818H34.2091V18.85C34.2091 18.0318 33.9773 17.2818 33.6091 16.6136C33.8 16.559 34.0046 16.5318 34.2091 16.5318C35.4909 16.5318 36.5409 17.5681 36.5409 18.8636V21.1954H38.8727V18.85C38.8591 16.2727 36.7864 14.2 34.2091 14.2Z"
          fill="#00457D"
        />
        <path
          d="M28.9046 11.5V10.4091H27.8955V11.5H26.8046V12.5227H27.8955V13.6136H28.9046V12.5227H30.0091V11.5H28.9046Z"
          fill="#00457D"
        />
        <path
          d="M40.0318 26.9773C40.0318 29.7318 39.0773 32.2546 37.4818 34.2455C36.5545 30.0864 32.8455 26.9773 28.4136 26.9773C25.6318 26.9773 23.1364 28.2046 21.4318 30.1409V26.9773H9.79999C9.79999 37.2591 18.1318 45.5909 28.4 45.5909C38.6682 45.5909 47 37.2591 47 26.9909H40.0318V26.9773ZM12.3091 29.3091H19.1V36.2909C19.1 38.1046 19.6318 39.7955 20.5318 41.2409C16.1682 38.8137 13.0454 34.45 12.3091 29.3091ZM28.4 43.2591C24.5545 43.2591 21.4182 40.1364 21.4182 36.2773C21.4182 32.4182 24.5409 29.3091 28.4 29.3091C32.2591 29.3091 35.3682 32.4318 35.3682 36.2637C34.5773 36.8637 33.7182 37.3546 32.7909 37.7364C32.9409 37.2728 33.05 36.7955 33.05 36.2773C33.05 33.6455 30.9636 31.6273 28.4 31.6273C25.8364 31.6273 23.75 33.7 23.75 36.2773C23.75 38.8546 25.8227 40.9273 28.4 40.9273C35.3136 40.9273 41.0545 35.8955 42.1591 29.3091H44.5182C43.4 37.1909 36.5955 43.2591 28.4 43.2591ZM30.7318 36.2773C30.7318 37.5591 29.6954 38.5955 28.4136 38.5955C27.1318 38.5955 26.0954 37.5591 26.0954 36.2773C26.0954 34.9955 27.1318 33.9591 28.4136 33.9591C29.6954 33.9591 30.7318 34.9955 30.7318 36.2773Z"
          fill="#00457D"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_356_3337"
          x="6.29999"
          y="16.101"
          width="44.1"
          height="44.8"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2.8" />
          <feGaussianBlur stdDeviation="1.75" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_356_3337"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_356_3337"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_356_3337"
          x="33.6"
          y="17.501"
          width="14"
          height="14"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1.4" />
          <feGaussianBlur stdDeviation="1.4" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_356_3337"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_356_3337"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_356_3337"
          x1="28.35"
          y1="16.801"
          x2="28.35"
          y2="54.601"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="white" stopOpacity="0.44" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_356_3337"
          x1="28.0008"
          y1="2.80078"
          x2="28.0008"
          y2="53.2008"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#DADADA" />
          <stop offset="1" stopColor="#CDCDCD" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_356_3337"
          x1="27.9988"
          y1="2.80078"
          x2="27.9988"
          y2="53.2008"
          gradientUnits="userSpaceOnUse">
          <stop stopOpacity="0.25" />
          <stop offset="1" stopOpacity="0.15" />
        </linearGradient>
        <clipPath id="clip0_356_3337">
          <rect
            x="2.80078"
            y="2.80078"
            width="50.4"
            height="50.4"
            rx="25.2"
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export const Correos = styled(Svg)
