'use client'
import { styled } from '../../config'

function Svg({ active, ...rest }) {
  if (active) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={56}
        height={56}
        viewBox="0 0 56 56"
        fill="none"
        {...rest}>
        <circle cx={28} cy={28} r={28} fill="#F4F4F4" />
        <g clipPath="url(#a)">
          <circle cx={28} cy={28.001} r={25.2} fill="#EEE" />
          <g filter="url(#b)">
            <rect
              width={37.1}
              height={37.8}
              x={9.8}
              y={16.801}
              fill="#fff"
              rx={2.1}
            />
            <rect
              width={36.4}
              height={37.1}
              x={10.15}
              y={17.151}
              stroke="url(#c)"
              strokeWidth={0.7}
              rx={1.75}
            />
          </g>
          <path fill="#F0F0F0" d="m40.6 29.821-2.8.98v-4.9h5.6v4.9l-2.8-.98Z" />
          <rect
            width={2.8}
            height={2.8}
            x={11.9}
            y={34.301}
            fill="#E1E1E1"
            rx={0.7}
          />
          <rect
            width={19.6}
            height={2.8}
            x={11.9}
            y={21.701}
            fill="#E1E1E1"
            rx={0.7}
          />
          <rect
            width={13.3}
            height={2.8}
            x={11.9}
            y={25.901}
            fill="#E1E1E1"
            rx={0.7}
          />
          <rect
            width={24.5}
            height={2.8}
            x={16.8}
            y={34.301}
            fill="#F0F0F0"
            rx={0.7}
          />
          <rect
            width={13.3}
            height={2.8}
            x={16.8}
            y={38.501}
            fill="#F0F0F0"
            rx={0.7}
          />
          <rect
            width={20.3}
            height={2.8}
            x={16.8}
            y={42.701}
            fill="#F0F0F0"
            rx={0.7}
          />
          <rect
            width={2.8}
            height={2.8}
            x={11.9}
            y={38.501}
            fill="#E1E1E1"
            rx={0.7}
          />
          <rect
            width={2.8}
            height={2.8}
            x={11.9}
            y={42.701}
            fill="#E1E1E1"
            rx={0.7}
          />
          <g filter="url(#d)">
            <circle cx={40.6} cy={23.101} r={4.2} fill="#F0F0F0" />
          </g>
          <circle cx={40.6} cy={23.101} r={2.8} fill="#E1E1E1" />
        </g>
        <rect
          width={49.7}
          height={49.7}
          x={3.151}
          y={3.151}
          stroke="url(#e)"
          strokeWidth={0.7}
          rx={24.85}
        />
        <circle cx={28} cy={28} r={28} fill="#E8112D" />
        <g clipPath="url(#f)">
          <g clipPath="url(#g)">
            <path
              fill="#fff"
              d="m4.516 28.228 23.27-13.937 23.27 13.937-23.27 13.937-23.27-13.937Z"
            />
            <path
              fill="#E8112D"
              d="m4.516 28.228 23.27-13.937 23.27 13.937-23.27 13.937-23.27-13.937ZM27.787 5-11 28.228l38.787 23.228 38.786-23.228L27.787 5Z"
            />
            <path
              fill="#0038A8"
              d="M46.207 20.972H9.366v14.522h36.841V20.972Z"
            />
            <path
              fill="#fff"
              d="M38.891 29.13c0-.957.335-1.915 1.443-1.915 1.117 0 1.452.957 1.452 1.915 0 .947-.335 1.904-1.452 1.904-1.108-.01-1.443-.966-1.443-1.904Zm-1.648 0c0 1.886 1.21 3.13 3.081 3.13 1.88 0 3.09-1.244 3.09-3.13 0-1.905-1.21-3.15-3.09-3.15-1.861 0-3.08 1.245-3.08 3.15Zm-4.049 2.973h1.639v-2.686c0-1.05.419-1.914 1.62-1.914.195 0 .437.02.586.056v-1.524a1.126 1.126 0 0 0-.354-.055c-.8 0-1.601.52-1.908 1.263l-.028-1.105h-1.555v5.965Zm-2.224-7.75h-1.638v1.785h-.996v1.096h.996v3.512c0 1.19.875 1.412 1.88 1.412.326 0 .68-.009.959-.055V30.83a2.353 2.353 0 0 1-.503.046c-.558 0-.689-.14-.689-.687v-2.946h1.2v-1.096h-1.2v-1.793h-.01Zm-7.363 4.126c.019-.464.326-1.264 1.36-1.264.8 0 1.153.437 1.302 1.264h-2.662Zm4.31 1.04c.112-1.83-.866-3.54-2.895-3.54-1.815 0-3.044 1.357-3.044 3.15 0 1.84 1.164 3.132 3.044 3.132 1.35 0 2.336-.604 2.793-2.007h-1.443c-.103.371-.633.77-1.294.77-.912 0-1.424-.473-1.462-1.504h4.3ZM12.27 32.104h1.694v-5.77l2.048 5.77h1.396l2.02-5.835.028 5.835h1.694V23.88H18.6l-1.825 5.649-1.954-5.658h-2.55v8.232Z"
            />
          </g>
        </g>
        <rect
          width={49.7}
          height={49.7}
          x={3.149}
          y={3.151}
          stroke="url(#h)"
          strokeOpacity={0.5}
          strokeWidth={0.7}
          rx={24.85}
        />
        <defs>
          <linearGradient
            id="c"
            x1={28.35}
            x2={28.35}
            y1={16.801}
            y2={54.601}
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#fff" stopOpacity={0.44} />
            <stop offset={1} stopColor="#fff" stopOpacity={0} />
          </linearGradient>
          <linearGradient
            id="e"
            x1={28.001}
            x2={28.001}
            y1={2.801}
            y2={53.201}
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#DADADA" />
            <stop offset={1} stopColor="#CDCDCD" />
          </linearGradient>
          <linearGradient
            id="h"
            x1={27.999}
            x2={27.999}
            y1={2.801}
            y2={53.201}
            gradientUnits="userSpaceOnUse">
            <stop stopOpacity={0.25} />
            <stop offset={1} stopOpacity={0.15} />
          </linearGradient>
          <clipPath id="a">
            <rect
              width={50.4}
              height={50.4}
              x={2.801}
              y={2.801}
              fill="#fff"
              rx={25.2}
            />
          </clipPath>
          <clipPath id="f">
            <rect
              width={50.4}
              height={50.4}
              x={2.799}
              y={2.801}
              fill="#fff"
              rx={25.2}
            />
          </clipPath>
          <clipPath id="g">
            <path fill="#fff" d="M-11 5h77.564v46.456H-11z" />
          </clipPath>
          <filter
            id="b"
            width={44.1}
            height={44.8}
            x={6.3}
            y={16.101}
            colorInterpolationFilters="sRGB"
            filterUnits="userSpaceOnUse">
            <feFlood floodOpacity={0} result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              result="hardAlpha"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dy={2.8} />
            <feGaussianBlur stdDeviation={1.75} />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend
              in2="BackgroundImageFix"
              result="effect1_dropShadow_4345_574"
            />
            <feBlend
              in="SourceGraphic"
              in2="effect1_dropShadow_4345_574"
              result="shape"
            />
          </filter>
          <filter
            id="d"
            width={14}
            height={14}
            x={33.6}
            y={17.501}
            colorInterpolationFilters="sRGB"
            filterUnits="userSpaceOnUse">
            <feFlood floodOpacity={0} result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              result="hardAlpha"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dy={1.4} />
            <feGaussianBlur stdDeviation={1.4} />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0" />
            <feBlend
              in2="BackgroundImageFix"
              result="effect1_dropShadow_4345_574"
            />
            <feBlend
              in="SourceGraphic"
              in2="effect1_dropShadow_4345_574"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    )
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={56}
      height={56}
      viewBox="0 0 56 56"
      fill="none"
      {...rest}>
      <circle cx={28} cy={28} r={28} fill="#F4F4F4" />
      <g clipPath="url(#a)">
        <circle cx={28} cy={28.001} r={25.2} fill="#EEE" />
        <g filter="url(#b)">
          <rect
            width={37.1}
            height={37.8}
            x={9.8}
            y={16.801}
            fill="#fff"
            rx={2.1}
          />
          <rect
            width={36.4}
            height={37.1}
            x={10.15}
            y={17.151}
            stroke="url(#c)"
            strokeWidth={0.7}
            rx={1.75}
          />
        </g>
        <path fill="#F0F0F0" d="m40.6 29.821-2.8.98v-4.9h5.6v4.9l-2.8-.98Z" />
        <rect
          width={2.8}
          height={2.8}
          x={11.9}
          y={34.301}
          fill="#E1E1E1"
          rx={0.7}
        />
        <rect
          width={19.6}
          height={2.8}
          x={11.9}
          y={21.701}
          fill="#E1E1E1"
          rx={0.7}
        />
        <rect
          width={13.3}
          height={2.8}
          x={11.9}
          y={25.901}
          fill="#E1E1E1"
          rx={0.7}
        />
        <rect
          width={24.5}
          height={2.8}
          x={16.8}
          y={34.301}
          fill="#F0F0F0"
          rx={0.7}
        />
        <rect
          width={13.3}
          height={2.8}
          x={16.8}
          y={38.501}
          fill="#F0F0F0"
          rx={0.7}
        />
        <rect
          width={20.3}
          height={2.8}
          x={16.8}
          y={42.701}
          fill="#F0F0F0"
          rx={0.7}
        />
        <rect
          width={2.8}
          height={2.8}
          x={11.9}
          y={38.501}
          fill="#E1E1E1"
          rx={0.7}
        />
        <rect
          width={2.8}
          height={2.8}
          x={11.9}
          y={42.701}
          fill="#E1E1E1"
          rx={0.7}
        />
        <g filter="url(#d)">
          <circle cx={40.6} cy={23.101} r={4.2} fill="#F0F0F0" />
        </g>
        <circle cx={40.6} cy={23.101} r={2.8} fill="#E1E1E1" />
      </g>
      <rect
        width={49.7}
        height={49.7}
        x={3.151}
        y={3.151}
        stroke="url(#e)"
        strokeWidth={0.7}
        rx={24.85}
      />
      <circle cx={28} cy={28} r={28} fill="#E8112D" />
      <g opacity={0.47}>
        <g clipPath="url(#f)">
          <g clipPath="url(#g)">
            <path
              fill="#fff"
              d="m4.516 28.228 23.27-13.937 23.27 13.937-23.27 13.937-23.27-13.937Z"
            />
            <path
              fill="#E8112D"
              d="m4.516 28.228 23.27-13.937 23.27 13.937-23.27 13.937-23.27-13.937ZM27.787 5-11 28.228l38.787 23.228 38.786-23.228L27.787 5Z"
            />
            <path
              fill="#0038A8"
              d="M46.207 20.972H9.366v14.522h36.841V20.972Z"
            />
            <path
              fill="#fff"
              d="M38.891 29.13c0-.957.335-1.915 1.443-1.915 1.117 0 1.452.957 1.452 1.915 0 .947-.335 1.904-1.452 1.904-1.108-.01-1.443-.966-1.443-1.904Zm-1.648 0c0 1.886 1.21 3.13 3.081 3.13 1.88 0 3.09-1.244 3.09-3.13 0-1.905-1.21-3.15-3.09-3.15-1.861 0-3.08 1.245-3.08 3.15Zm-4.049 2.973h1.639v-2.686c0-1.05.419-1.914 1.62-1.914.195 0 .437.02.586.056v-1.524a1.126 1.126 0 0 0-.354-.055c-.8 0-1.601.52-1.908 1.263l-.028-1.105h-1.555v5.965Zm-2.224-7.75h-1.638v1.785h-.996v1.096h.996v3.512c0 1.19.875 1.412 1.88 1.412.326 0 .68-.009.959-.055V30.83a2.353 2.353 0 0 1-.503.046c-.558 0-.689-.14-.689-.687v-2.946h1.2v-1.096h-1.2v-1.793h-.01Zm-7.363 4.126c.019-.464.326-1.264 1.36-1.264.8 0 1.153.437 1.302 1.264h-2.662Zm4.31 1.04c.112-1.83-.866-3.54-2.895-3.54-1.815 0-3.044 1.357-3.044 3.15 0 1.84 1.164 3.132 3.044 3.132 1.35 0 2.336-.604 2.793-2.007h-1.443c-.103.371-.633.77-1.294.77-.912 0-1.424-.473-1.462-1.504h4.3ZM12.27 32.104h1.694v-5.77l2.048 5.77h1.396l2.02-5.835.028 5.835h1.694V23.88H18.6l-1.825 5.649-1.954-5.658h-2.55v8.232Z"
            />
          </g>
        </g>
        <rect
          width={49.7}
          height={49.7}
          x={3.149}
          y={3.151}
          stroke="url(#h)"
          strokeOpacity={0.5}
          strokeWidth={0.7}
          rx={24.85}
        />
      </g>
      <defs>
        <linearGradient
          id="c"
          x1={28.35}
          x2={28.35}
          y1={16.801}
          y2={54.601}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#fff" stopOpacity={0.44} />
          <stop offset={1} stopColor="#fff" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="e"
          x1={28.001}
          x2={28.001}
          y1={2.801}
          y2={53.201}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#DADADA" />
          <stop offset={1} stopColor="#CDCDCD" />
        </linearGradient>
        <linearGradient
          id="h"
          x1={27.999}
          x2={27.999}
          y1={2.801}
          y2={53.201}
          gradientUnits="userSpaceOnUse">
          <stop stopOpacity={0.25} />
          <stop offset={1} stopOpacity={0.15} />
        </linearGradient>
        <clipPath id="a">
          <rect
            width={50.4}
            height={50.4}
            x={2.801}
            y={2.801}
            fill="#fff"
            rx={25.2}
          />
        </clipPath>
        <clipPath id="f">
          <rect
            width={50.4}
            height={50.4}
            x={2.799}
            y={2.801}
            fill="#fff"
            rx={25.2}
          />
        </clipPath>
        <clipPath id="g">
          <path fill="#fff" d="M-11 5h77.564v46.456H-11z" />
        </clipPath>
        <filter
          id="b"
          width={44.1}
          height={44.8}
          x={6.3}
          y={16.101}
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse">
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy={2.8} />
          <feGaussianBlur stdDeviation={1.75} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_4345_676"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_4345_676"
            result="shape"
          />
        </filter>
        <filter
          id="d"
          width={14}
          height={14}
          x={33.6}
          y={17.501}
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse">
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy={1.4} />
          <feGaussianBlur stdDeviation={1.4} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_4345_676"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_4345_676"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  )
}

export const MetroDeMadrid = styled(Svg)
