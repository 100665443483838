'use client'
import { styled } from '../../config'

function Svg({ active, ...rest }) {
  if (active) {
    return (
      <svg
        width="56"
        height="56"
        viewBox="0 0 56 56"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}>
        <circle cx="28" cy="28" r="28" fill="#A7B4CB" />

        <rect
          x="3.1498"
          y="3.15078"
          width="49.7"
          height="49.7"
          rx="24.85"
          stroke="url(#paint1_linear_5_55)"
          strokeWidth="0.7"
        />
        <g clipPath="url(#clip1_5_55)">
          <rect
            x="2.79883"
            y="2.80078"
            width="50.4"
            height="50.4"
            rx="25.2"
            fill="#8994A7"
          />
          <circle
            cx="27.9998"
            cy="28.0008"
            r="24.85"
            stroke="url(#paint2_linear_5_55)"
            strokeWidth="0.7"
          />
          <g filter="url(#filter2_d_5_55)">
            <path
              d="M10 19.1C10 17.9402 10.9402 17 12.1 17H28.5H36.1792H44.9C46.0598 17 47 17.9402 47 19.1V26.25V35.5V48C47 51.3137 44.3137 54 41 54H16C12.6863 54 10 51.3137 10 48V19.1Z"
              fill="#F2F5FA"
            />
            <path
              d="M10.35 19.1C10.35 18.1335 11.1335 17.35 12.1 17.35H28.5H36.1792H44.9C45.8665 17.35 46.65 18.1335 46.65 19.1V26.25V35.5V48C46.65 51.1204 44.1204 53.65 41 53.65H16C12.8796 53.65 10.35 51.1204 10.35 48V19.1Z"
              stroke="url(#paint3_linear_5_55)"
              strokeWidth="0.7"
            />
          </g>
          <rect
            x="20"
            y="21.7012"
            width="19.6"
            height="2.8"
            rx="0.7"
            fill="#8994A7"
          />
          <rect
            x="23"
            y="26"
            width="13.3"
            height="2.8"
            rx="0.7"
            fill="#8994A7"
          />
          <path d="M14 17.5L14 50" stroke="#E5E8EC" />
          <rect x="19" y="35" width="23" height="12" rx="1" fill="#E7EAEE" />
        </g>
        <rect
          x="3.14883"
          y="3.15078"
          width="49.7"
          height="49.7"
          rx="24.85"
          stroke="black"
          strokeOpacity="0.07"
          strokeWidth="0.7"
        />
        <defs>
          <filter
            id="filter0_d_5_55"
            x="6.29883"
            y="16.1008"
            width="44.0996"
            height="44.8008"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="2.8" />
            <feGaussianBlur stdDeviation="1.75" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_5_55"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_5_55"
              result="shape"
            />
          </filter>
          <filter
            id="filter1_d_5_55"
            x="33.5994"
            y="17.5004"
            width="13.9994"
            height="14.0004"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="1.4" />
            <feGaussianBlur stdDeviation="1.4" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_5_55"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_5_55"
              result="shape"
            />
          </filter>
          <filter
            id="filter2_d_5_55"
            x="6.5"
            y="16.3"
            width="44"
            height="44"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="2.8" />
            <feGaussianBlur stdDeviation="1.75" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_5_55"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_5_55"
              result="shape"
            />
          </filter>
          <linearGradient
            id="paint0_linear_5_55"
            x1="28.3488"
            y1="16.8008"
            x2="28.3488"
            y2="54.6008"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="white" stopOpacity="0.44" />
            <stop offset="1" stopColor="white" stopOpacity="0" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_5_55"
            x1="27.9998"
            y1="2.80078"
            x2="27.9998"
            y2="53.2008"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#DADADA" />
            <stop offset="1" stopColor="#CDCDCD" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_5_55"
            x1="27.9998"
            y1="2.80078"
            x2="27.9998"
            y2="53.2008"
            gradientUnits="userSpaceOnUse">
            <stop stopOpacity="0.25" />
            <stop offset="1" stopOpacity="0.15" />
          </linearGradient>
          <linearGradient
            id="paint3_linear_5_55"
            x1="28.5"
            y1="17"
            x2="28.5"
            y2="54"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="white" stopOpacity="0.44" />
            <stop offset="1" stopColor="white" stopOpacity="0" />
          </linearGradient>
          <clipPath id="clip0_5_55">
            <rect
              x="2.7998"
              y="2.80078"
              width="50.4"
              height="50.4"
              rx="25.2"
              fill="white"
            />
          </clipPath>
          <clipPath id="clip1_5_55">
            <rect
              x="2.79883"
              y="2.80078"
              width="50.4"
              height="50.4"
              rx="25.2"
              fill="white"
            />
          </clipPath>
        </defs>
      </svg>
    )
  }
  return (
    <svg
      width="56"
      height="56"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}>
      <circle cx="28" cy="28" r="27.5" fill="white" stroke="#F1F1F1" />
      <g clipPath="url(#clip0_5_55)">
        <circle cx="27.9988" cy="28.0008" r="25.2" fill="#EEEEEE" />
        <g filter="url(#filter0_d_5_55)">
          <rect
            x="9.79883"
            y="16.8008"
            width="37.1"
            height="37.8"
            rx="2.1"
            fill="white"
          />
          <rect
            x="10.1488"
            y="17.1508"
            width="36.4"
            height="37.1"
            rx="1.75"
            stroke="url(#paint0_linear_5_55)"
            strokeWidth="0.7"
          />
        </g>
        <path
          d="M40.5988 29.8204L37.7988 30.8004V25.9004H43.3988V30.8004L40.5988 29.8204Z"
          fill="#F0F0F0"
        />
        <rect
          x="11.8994"
          y="34.3008"
          width="2.8"
          height="2.8"
          rx="0.7"
          fill="#E1E1E1"
        />
        <rect
          x="11.8994"
          y="21.7012"
          width="19.6"
          height="2.8"
          rx="0.7"
          fill="#E1E1E1"
        />
        <rect
          x="11.8994"
          y="25.9004"
          width="13.3"
          height="2.8"
          rx="0.7"
          fill="#E1E1E1"
        />
        <rect
          x="16.7988"
          y="34.3008"
          width="24.5"
          height="2.8"
          rx="0.7"
          fill="#F0F0F0"
        />
        <rect
          x="16.7988"
          y="38.5"
          width="13.3"
          height="2.8"
          rx="0.7"
          fill="#F0F0F0"
        />
        <rect
          x="16.7988"
          y="42.7012"
          width="20.3"
          height="2.8"
          rx="0.7"
          fill="#F0F0F0"
        />
        <rect
          x="11.8994"
          y="38.5"
          width="2.8"
          height="2.8"
          rx="0.7"
          fill="#E1E1E1"
        />
        <rect
          x="11.8994"
          y="42.7012"
          width="2.8"
          height="2.8"
          rx="0.7"
          fill="#E1E1E1"
        />
        <g filter="url(#filter1_d_5_55)">
          <circle cx="40.5994" cy="23.1004" r="4.2" fill="#F0F0F0" />
        </g>
        <circle cx="40.5988" cy="23.1008" r="2.8" fill="#E1E1E1" />
      </g>
      <rect
        x="3.1498"
        y="3.15078"
        width="49.7"
        height="49.7"
        rx="24.85"
        stroke="url(#paint1_linear_5_55)"
        strokeWidth="0.7"
      />
      <g clipPath="url(#clip1_5_55)">
        <circle cx="27.9998" cy="28.0008" r="25.2" fill="#E6EEFC" />
        <g filter="url(#filter2_d_5_55)">
          <path
            d="M10 19.1C10 17.9402 10.9402 17 12.1 17H28.5H36.1792H44.9C46.0598 17 47 17.9402 47 19.1V26.25V35.5V48C47 51.3137 44.3137 54 41 54H16C12.6863 54 10 51.3137 10 48V19.1Z"
            fill="#F2F5FA"
          />
          <path
            d="M10.35 48V19.1C10.35 18.1335 11.1335 17.35 12.1 17.35H28.5H36.1792H44.9C45.8665 17.35 46.65 18.1335 46.65 19.1V26.25V35.5V48C46.65 51.1204 44.1204 53.65 41 53.65H16C12.8796 53.65 10.35 51.1204 10.35 48Z"
            stroke="url(#paint2_linear_5_55)"
            strokeWidth="0.7"
          />
        </g>
        <rect
          x="20"
          y="21.7012"
          width="19.6"
          height="2.8"
          rx="0.7"
          fill="#8994A7"
        />
        <rect x="23" y="26" width="13.3" height="2.8" rx="0.7" fill="#8994A7" />
        <path d="M14 17.5L14 50" stroke="#E5E8EC" />
        <rect x="19" y="35" width="23" height="12" rx="1" fill="#E7EAEE" />
      </g>
      <rect
        x="3.14883"
        y="3.15078"
        width="49.7"
        height="49.7"
        rx="24.85"
        stroke="black"
        strokeOpacity="0.07"
        strokeWidth="0.7"
      />
      <defs>
        <filter
          id="filter0_d_5_55"
          x="6.29883"
          y="16.1008"
          width="44.0996"
          height="44.8008"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2.8" />
          <feGaussianBlur stdDeviation="1.75" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_5_55"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_5_55"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_5_55"
          x="33.5994"
          y="17.5004"
          width="14.0004"
          height="14.0004"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1.4" />
          <feGaussianBlur stdDeviation="1.4" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_5_55"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_5_55"
            result="shape"
          />
        </filter>
        <filter
          id="filter2_d_5_55"
          x="6.5"
          y="16.3"
          width="44"
          height="44"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2.8" />
          <feGaussianBlur stdDeviation="1.75" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_5_55"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_5_55"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_5_55"
          x1="28.3488"
          y1="16.8008"
          x2="28.3488"
          y2="54.6008"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="white" stopOpacity="0.44" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_5_55"
          x1="27.9998"
          y1="2.80078"
          x2="27.9998"
          y2="53.2008"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#DADADA" />
          <stop offset="1" stopColor="#CDCDCD" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_5_55"
          x1="28.5"
          y1="17"
          x2="28.5"
          y2="54"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="white" stopOpacity="0.44" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <clipPath id="clip0_5_55">
          <rect
            x="2.7998"
            y="2.80078"
            width="50.4"
            height="50.4"
            rx="25.2"
            fill="white"
          />
        </clipPath>
        <clipPath id="clip1_5_55">
          <rect
            x="2.79883"
            y="2.80078"
            width="50.4"
            height="50.4"
            rx="25.2"
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export const BomberoGeneral = styled(Svg)
