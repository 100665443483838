'use client'
import { styled } from '../../config'

function Svg({ active, ...rest }) {
  if (active) {
    return (
      <svg
        width="56"
        height="56"
        viewBox="0 0 56 56"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}>
        <circle cx="28" cy="28" r="28" fill="#F4F4F4" />

        <rect
          x="3.15078"
          y="3.15078"
          width="49.7"
          height="49.7"
          rx="24.85"
          stroke="url(#paint1_linear_5_55)"
          strokeWidth="0.7"
        />
        <circle cx="28" cy="28" r="28" fill="#FCDD0D" />
        <g clipPath="url(#clip1_5_55)">
          <rect y="3" width="56" height="5.6" fill="#FCDD09" />
          <rect y="8.59961" width="56" height="5.6" fill="#DA121A" />
          <rect y="14.1992" width="56" height="5.6" fill="#FCDD09" />
          <rect y="19.8008" width="56" height="5.6" fill="#DA121A" />
          <rect y="25.4004" width="56" height="5.6" fill="#FCDD09" />
          <rect y="31" width="56" height="5.6" fill="#DA121A" />
          <rect y="36.5996" width="56" height="5.6" fill="#FCDD09" />
          <rect y="42.1992" width="56" height="5.6" fill="#DA121A" />
          <rect y="47.8008" width="56" height="5.6" fill="#FCDD09" />
        </g>
        <rect
          x="3.14883"
          y="3.15078"
          width="49.7"
          height="49.7"
          rx="24.85"
          stroke="url(#paint2_linear_5_55)"
          strokeWidth="0.7"
        />
        <defs>
          <filter
            id="filter0_d_5_55"
            x="6.30078"
            y="16.1008"
            width="44.0996"
            height="44.8008"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="2.8" />
            <feGaussianBlur stdDeviation="1.75" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_5_55"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_5_55"
              result="shape"
            />
          </filter>
          <filter
            id="filter1_d_5_55"
            x="33.6004"
            y="17.5004"
            width="14.0004"
            height="14.0004"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="1.4" />
            <feGaussianBlur stdDeviation="1.4" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_5_55"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_5_55"
              result="shape"
            />
          </filter>
          <linearGradient
            id="paint0_linear_5_55"
            x1="28.3508"
            y1="16.8008"
            x2="28.3508"
            y2="54.6008"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="white" stopOpacity="0.44" />
            <stop offset="1" stopColor="white" stopOpacity="0" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_5_55"
            x1="28.0008"
            y1="2.80078"
            x2="28.0008"
            y2="53.2008"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#DADADA" />
            <stop offset="1" stopColor="#CDCDCD" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_5_55"
            x1="27.9988"
            y1="2.80078"
            x2="27.9988"
            y2="53.2008"
            gradientUnits="userSpaceOnUse">
            <stop stopOpacity="0.25" />
            <stop offset="1" stopOpacity="0.15" />
          </linearGradient>
          <clipPath id="clip0_5_55">
            <rect
              x="2.80078"
              y="2.80078"
              width="50.4"
              height="50.4"
              rx="25.2"
              fill="white"
            />
          </clipPath>
          <clipPath id="clip1_5_55">
            <rect
              x="2.79883"
              y="2.80078"
              width="50.4"
              height="50.4"
              rx="25.2"
              fill="white"
            />
          </clipPath>
        </defs>
      </svg>
    )
  }
  return (
    <svg
      width="56"
      height="56"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}>
      <circle cx="28" cy="28" r="28" fill="#F4F4F4" />
      <g clipPath="url(#clip0_5_55)">
        <circle cx="28.0008" cy="28.0008" r="25.2" fill="#EEEEEE" />
        <g filter="url(#filter0_d_5_55)">
          <rect
            x="9.80078"
            y="16.8008"
            width="37.1"
            height="37.8"
            rx="2.1"
            fill="white"
          />
          <rect
            x="10.1508"
            y="17.1508"
            width="36.4"
            height="37.1"
            rx="1.75"
            stroke="url(#paint0_linear_5_55)"
            strokeWidth="0.7"
          />
        </g>
        <path
          d="M40.6008 29.8204L37.8008 30.8004V25.9004H43.4008V30.8004L40.6008 29.8204Z"
          fill="#F0F0F0"
        />
        <rect
          x="11.9004"
          y="34.3008"
          width="2.8"
          height="2.8"
          rx="0.7"
          fill="#E1E1E1"
        />
        <rect
          x="11.9004"
          y="21.7012"
          width="19.6"
          height="2.8"
          rx="0.7"
          fill="#E1E1E1"
        />
        <rect
          x="11.9004"
          y="25.9004"
          width="13.3"
          height="2.8"
          rx="0.7"
          fill="#E1E1E1"
        />
        <rect
          x="16.8008"
          y="34.3008"
          width="24.5"
          height="2.8"
          rx="0.7"
          fill="#F0F0F0"
        />
        <rect
          x="16.8008"
          y="38.5"
          width="13.3"
          height="2.8"
          rx="0.7"
          fill="#F0F0F0"
        />
        <rect
          x="16.8008"
          y="42.7012"
          width="20.3"
          height="2.8"
          rx="0.7"
          fill="#F0F0F0"
        />
        <rect
          x="11.9004"
          y="38.5"
          width="2.8"
          height="2.8"
          rx="0.7"
          fill="#E1E1E1"
        />
        <rect
          x="11.9004"
          y="42.7012"
          width="2.8"
          height="2.8"
          rx="0.7"
          fill="#E1E1E1"
        />
        <g filter="url(#filter1_d_5_55)">
          <circle cx="40.6004" cy="23.1004" r="4.2" fill="#F0F0F0" />
        </g>
        <circle cx="40.6008" cy="23.1008" r="2.8" fill="#E1E1E1" />
      </g>
      <rect
        x="3.15078"
        y="3.15078"
        width="49.7"
        height="49.7"
        rx="24.85"
        stroke="url(#paint1_linear_5_55)"
        strokeWidth="0.7"
      />
      <circle cx="28" cy="28" r="27.5" fill="white" stroke="#F1F1F1" />
      <g clipPath="url(#clip1_5_55)">
        <g opacity="0.4" clipPath="url(#clip2_5_55)">
          <rect y="3" width="56" height="5.6" fill="#FCDD09" />
          <rect y="8.59961" width="56" height="5.6" fill="#DA121A" />
          <rect y="14.1992" width="56" height="5.6" fill="#FCDD09" />
          <rect y="19.8008" width="56" height="5.6" fill="#DA121A" />
          <rect y="25.4004" width="56" height="5.6" fill="#FCDD09" />
          <rect y="31" width="56" height="5.6" fill="#DA121A" />
          <rect y="36.5996" width="56" height="5.6" fill="#FCDD09" />
          <rect y="42.1992" width="56" height="5.6" fill="#DA121A" />
          <rect y="47.8008" width="56" height="5.6" fill="#FCDD09" />
        </g>
      </g>
      <rect
        x="3.14883"
        y="3.15078"
        width="49.7"
        height="49.7"
        rx="24.85"
        stroke="url(#paint2_linear_5_55)"
        strokeWidth="0.7"
      />
      <defs>
        <filter
          id="filter0_d_5_55"
          x="6.30078"
          y="16.1008"
          width="44.0996"
          height="44.8008"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2.8" />
          <feGaussianBlur stdDeviation="1.75" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_5_55"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_5_55"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_5_55"
          x="33.6004"
          y="17.5004"
          width="14.0004"
          height="14.0004"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1.4" />
          <feGaussianBlur stdDeviation="1.4" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_5_55"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_5_55"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_5_55"
          x1="28.3508"
          y1="16.8008"
          x2="28.3508"
          y2="54.6008"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="white" stopOpacity="0.44" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_5_55"
          x1="28.0008"
          y1="2.80078"
          x2="28.0008"
          y2="53.2008"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#DADADA" />
          <stop offset="1" stopColor="#CDCDCD" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_5_55"
          x1="27.9988"
          y1="2.80078"
          x2="27.9988"
          y2="53.2008"
          gradientUnits="userSpaceOnUse">
          <stop stopOpacity="0.1" />
          <stop offset="1" stopOpacity="0.05" />
        </linearGradient>
        <clipPath id="clip0_5_55">
          <rect
            x="2.80078"
            y="2.80078"
            width="50.4"
            height="50.4"
            rx="25.2"
            fill="white"
          />
        </clipPath>
        <clipPath id="clip1_5_55">
          <rect
            x="2.79883"
            y="2.80078"
            width="50.4"
            height="50.4"
            rx="25.2"
            fill="white"
          />
        </clipPath>
        <clipPath id="clip2_5_55">
          <rect
            width="56"
            height="50.4"
            fill="white"
            transform="translate(0 3)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export const BomberoCatalunya = styled(Svg, {})
