'use client'
import { styled } from '../../config'

function Svg({ active, ...rest }) {
  if (active) {
    return (
      <svg
        width="56"
        height="56"
        viewBox="0 0 56 56"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}>
        <circle cx="28" cy="28" r="28" fill="#F4F4F4" />

        <rect
          x="3.15078"
          y="3.15078"
          width="49.7"
          height="49.7"
          rx="24.85"
          stroke="url(#paint1_linear_5_55)"
          strokeWidth="0.7"
        />
        <circle cx="28" cy="28" r="28" fill="#E32D3F" />
        <g clipPath="url(#clip1_5_55)">
          <circle cx="27.9988" cy="28.0008" r="25.2" fill="#C60B1E" />
          <path
            d="M11.65 20L12.694 23.2131H16.0724L13.3392 25.1988L14.3832 28.4119L11.65 26.4261L8.91679 28.4119L9.96078 25.1988L7.22759 23.2131H10.606L11.65 20Z"
            fill="white"
          />
          <path
            d="M17.0992 27.6992L18.1432 30.9123H21.5216L18.7884 32.8981L19.8324 36.1111L17.0992 34.1253L14.366 36.1111L15.41 32.8981L12.6768 30.9123H16.0552L17.0992 27.6992Z"
            fill="white"
          />
          <path
            d="M27.9996 27.6992L29.0436 30.9123H32.422L29.6888 32.8981L30.7328 36.1111L27.9996 34.1253L25.2664 36.1111L26.3104 32.8981L23.5772 30.9123H26.9556L27.9996 27.6992Z"
            fill="white"
          />
          <path
            d="M38.9 27.6992L39.944 30.9123H43.3224L40.5892 32.8981L41.6332 36.1111L38.9 34.1253L36.1668 36.1111L37.2108 32.8981L34.4776 30.9123H37.856L38.9 27.6992Z"
            fill="white"
          />
          <path
            d="M22.5504 20L23.5944 23.2131H26.9728L24.2396 25.1988L25.2836 28.4119L22.5504 26.4261L19.8172 28.4119L20.8612 25.1988L18.128 23.2131H21.5064L22.5504 20Z"
            fill="white"
          />
          <path
            d="M33.4508 20L34.4948 23.2131H37.8732L35.14 25.1988L36.184 28.4119L33.4508 26.4261L30.7176 28.4119L31.7616 25.1988L29.0284 23.2131H32.4068L33.4508 20Z"
            fill="white"
          />
          <path
            d="M44.3512 20L45.3951 23.2131H48.7736L46.0404 25.1988L47.0844 28.4119L44.3512 26.4261L41.618 28.4119L42.662 25.1988L39.9288 23.2131H43.3072L44.3512 20Z"
            fill="white"
          />
        </g>
        <rect
          x="3.14883"
          y="3.15078"
          width="49.7"
          height="49.7"
          rx="24.85"
          stroke="url(#paint2_linear_5_55)"
          strokeWidth="0.7"
        />
        <defs>
          <filter
            id="filter0_d_5_55"
            x="6.30078"
            y="16.1008"
            width="44.0996"
            height="44.8008"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="2.8" />
            <feGaussianBlur stdDeviation="1.75" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_5_55"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_5_55"
              result="shape"
            />
          </filter>
          <filter
            id="filter1_d_5_55"
            x="33.6004"
            y="17.5004"
            width="14.0004"
            height="14.0004"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="1.4" />
            <feGaussianBlur stdDeviation="1.4" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_5_55"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_5_55"
              result="shape"
            />
          </filter>
          <linearGradient
            id="paint0_linear_5_55"
            x1="28.3508"
            y1="16.8008"
            x2="28.3508"
            y2="54.6008"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="white" stopOpacity="0.44" />
            <stop offset="1" stopColor="white" stopOpacity="0" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_5_55"
            x1="28.0008"
            y1="2.80078"
            x2="28.0008"
            y2="53.2008"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#DADADA" />
            <stop offset="1" stopColor="#CDCDCD" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_5_55"
            x1="27.9988"
            y1="2.80078"
            x2="27.9988"
            y2="53.2008"
            gradientUnits="userSpaceOnUse">
            <stop stopOpacity="0.25" />
            <stop offset="1" stopColor="#960917" stopOpacity="0.15" />
          </linearGradient>
          <clipPath id="clip0_5_55">
            <rect
              x="2.80078"
              y="2.80078"
              width="50.4"
              height="50.4"
              rx="25.2"
              fill="white"
            />
          </clipPath>
          <clipPath id="clip1_5_55">
            <rect
              x="2.79883"
              y="2.80078"
              width="50.4"
              height="50.4"
              rx="25.2"
              fill="white"
            />
          </clipPath>
        </defs>
      </svg>
    )
  }
  return (
    <svg
      width="56"
      height="56"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}>
      <circle cx="28" cy="28" r="28" fill="#F4F4F4" />
      <g clipPath="url(#clip0_5_55)">
        <circle cx="27.9998" cy="28.0008" r="25.2" fill="#EEEEEE" />
        <g filter="url(#filter0_d_5_55)">
          <rect
            x="9.7998"
            y="16.8008"
            width="37.1"
            height="37.8"
            rx="2.1"
            fill="white"
          />
          <rect
            x="10.1498"
            y="17.1508"
            width="36.4"
            height="37.1"
            rx="1.75"
            stroke="url(#paint0_linear_5_55)"
            strokeWidth="0.7"
          />
        </g>
        <path
          d="M40.5998 29.8204L37.7998 30.8004V25.9004H43.3998V30.8004L40.5998 29.8204Z"
          fill="#F0F0F0"
        />
        <rect
          x="11.9004"
          y="34.3008"
          width="2.8"
          height="2.8"
          rx="0.7"
          fill="#E1E1E1"
        />
        <rect
          x="11.9004"
          y="21.7012"
          width="19.6"
          height="2.8"
          rx="0.7"
          fill="#E1E1E1"
        />
        <rect
          x="11.9004"
          y="25.9004"
          width="13.3"
          height="2.8"
          rx="0.7"
          fill="#E1E1E1"
        />
        <rect
          x="16.7998"
          y="34.3008"
          width="24.5"
          height="2.8"
          rx="0.7"
          fill="#F0F0F0"
        />
        <rect
          x="16.7998"
          y="38.5"
          width="13.3"
          height="2.8"
          rx="0.7"
          fill="#F0F0F0"
        />
        <rect
          x="16.7998"
          y="42.7012"
          width="20.3"
          height="2.8"
          rx="0.7"
          fill="#F0F0F0"
        />
        <rect
          x="11.9004"
          y="38.5"
          width="2.8"
          height="2.8"
          rx="0.7"
          fill="#E1E1E1"
        />
        <rect
          x="11.9004"
          y="42.7012"
          width="2.8"
          height="2.8"
          rx="0.7"
          fill="#E1E1E1"
        />
        <g filter="url(#filter1_d_5_55)">
          <circle cx="40.6004" cy="23.1004" r="4.2" fill="#F0F0F0" />
        </g>
        <circle cx="40.5998" cy="23.1008" r="2.8" fill="#E1E1E1" />
      </g>
      <rect
        x="3.15078"
        y="3.15078"
        width="49.7"
        height="49.7"
        rx="24.85"
        stroke="url(#paint1_linear_5_55)"
        strokeWidth="0.7"
      />
      <circle cx="28" cy="28" r="27.5" fill="white" stroke="#F1F1F1" />
      <g clipPath="url(#clip1_5_55)">
        <circle
          cx="27.9998"
          cy="28.0008"
          r="25.2"
          fill="#C60B1E"
          fillOpacity="0.05"
        />
        <path
          d="M11.65 20L12.694 23.2131H16.0724L13.3392 25.1988L14.3832 28.4119L11.65 26.4261L8.91679 28.4119L9.96078 25.1988L7.22759 23.2131H10.606L11.65 20Z"
          fill="#C60B1E"
        />
        <path
          d="M17.0992 27.6992L18.1432 30.9123H21.5216L18.7884 32.8981L19.8324 36.1111L17.0992 34.1253L14.366 36.1111L15.41 32.8981L12.6768 30.9123H16.0552L17.0992 27.6992Z"
          fill="#C60B1E"
        />
        <path
          d="M27.9996 27.6992L29.0436 30.9123H32.422L29.6888 32.8981L30.7328 36.1111L27.9996 34.1253L25.2664 36.1111L26.3104 32.8981L23.5772 30.9123H26.9556L27.9996 27.6992Z"
          fill="#C60B1E"
        />
        <path
          d="M38.899 27.6992L39.943 30.9123H43.3214L40.5882 32.8981L41.6322 36.1111L38.899 34.1253L36.1658 36.1111L37.2098 32.8981L34.4766 30.9123H37.855L38.899 27.6992Z"
          fill="#C60B1E"
        />
        <path
          d="M22.5504 20L23.5944 23.2131H26.9728L24.2396 25.1988L25.2836 28.4119L22.5504 26.4261L19.8172 28.4119L20.8612 25.1988L18.128 23.2131H21.5064L22.5504 20Z"
          fill="#C60B1E"
        />
        <path
          d="M33.4498 20L34.4938 23.2131H37.8722L35.139 25.1988L36.183 28.4119L33.4498 26.4261L30.7166 28.4119L31.7606 25.1988L29.0274 23.2131H32.4058L33.4498 20Z"
          fill="#C60B1E"
        />
        <path
          d="M44.3502 20L45.3942 23.2131H48.7726L46.0394 25.1988L47.0834 28.4119L44.3502 26.4261L41.617 28.4119L42.661 25.1988L39.9278 23.2131H43.3062L44.3502 20Z"
          fill="#C60B1E"
        />
      </g>
      <rect
        x="3.14883"
        y="3.15078"
        width="49.7"
        height="49.7"
        rx="24.85"
        stroke="url(#paint2_linear_5_55)"
        strokeWidth="0.7"
      />
      <defs>
        <filter
          id="filter0_d_5_55"
          x="6.2998"
          y="16.1008"
          width="44.0996"
          height="44.8008"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2.8" />
          <feGaussianBlur stdDeviation="1.75" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_5_55"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_5_55"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_5_55"
          x="33.6004"
          y="17.5004"
          width="14.0004"
          height="14.0004"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1.4" />
          <feGaussianBlur stdDeviation="1.4" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_5_55"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_5_55"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_5_55"
          x1="28.3498"
          y1="16.8008"
          x2="28.3498"
          y2="54.6008"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="white" stopOpacity="0.44" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_5_55"
          x1="28.0008"
          y1="2.80078"
          x2="28.0008"
          y2="53.2008"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#DADADA" />
          <stop offset="1" stopColor="#CDCDCD" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_5_55"
          x1="27.9988"
          y1="2.80078"
          x2="27.9988"
          y2="53.2008"
          gradientUnits="userSpaceOnUse">
          <stop stopOpacity="0.1" />
          <stop offset="1" stopOpacity="0.05" />
        </linearGradient>
        <clipPath id="clip0_5_55">
          <rect
            x="2.80078"
            y="2.80078"
            width="50.4"
            height="50.4"
            rx="25.2"
            fill="white"
          />
        </clipPath>
        <clipPath id="clip1_5_55">
          <rect
            x="2.79883"
            y="2.80078"
            width="50.4"
            height="50.4"
            rx="25.2"
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export const AdministrativoMadrid = styled(Svg)
